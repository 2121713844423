import { Draft } from "@reduxjs/toolkit";
import { GameState } from "../gamestate";
import { upgrades, UpgradeType } from "../upgrades/upgradeTypes";
import { allOf, conditionAlways, conditionHasBuilding, conditionHasResource, conditionHasUpgrade, UnlockCondition, unlockUpgrade } from "./unlockUtils";

type UpgradesUnlockRules = {
    [K in UpgradeType]: UnlockCondition
};

const upgradeUnlocks: UpgradesUnlockRules = {
    wheatToFarm: conditionAlways(),
    milkToCowPen: conditionAlways(),
    farmsToIrrigatedFarms: conditionHasBuilding('well', 1),
    cowsToFedCows: conditionHasBuilding('well', 1),
    bakeryInteractable: conditionHasUpgrade('fasterBakery'),
    grindstoneToWindmill: conditionHasUpgrade('produceProtocroissants'),
    churnToBarrel: conditionHasUpgrade('produceProtocroissants'),
    ovenToCoalOven: conditionHasResource('coal', 50),
    cacaoToFarm: conditionHasResource('cacao', 5),
    sugarcaneToPlantation: conditionHasResource('sugar', 5),

    ///////////////////////////////

    clickDoubled1: conditionHasResource('wheat', 20),
    clickDoubled2: conditionHasResource('milk', 20),
    clickConsumedHalved: allOf([
        conditionHasBuilding('grindstone', 5),
        conditionHasBuilding('churn', 5),
    ]),
    farmsProductionDoubled: conditionHasBuilding('farm', 5),
    cowsProductionDoubled: conditionHasBuilding('cows', 5),
    grindstoneProductionDoubled: conditionHasBuilding('grindstone', 5),
    churnProductionDoubled: conditionHasBuilding('churn', 5),
    ovenProductionUpgrade: conditionHasBuilding('oven', 5),

    unlockHero: conditionHasResource('anypastry', 20),
    unlockFirstBakery: conditionHasResource('anypastry', 50),
    fasterBakery: conditionHasResource('money', 0.1),

    produceGoldenPastries: conditionHasResource('egg', 10),
    discoveryYeast: allOf([
        conditionHasUpgrade('produceGoldenPastries'),
        conditionHasResource('money', 5),
    ]),
    produceProtocroissants: conditionHasResource('yeast', 10),
    clickProductionDoubled: conditionHasResource('anypastry', 200),
    fasterOvens: conditionHasUpgrade('bakeryInteractable'),

    laminationTechnique: conditionHasUpgrade('ovenToCoalOven'),
    unlockStudy: conditionHasResource('money', 60),
    fasterYeast: conditionHasResource('yeast', 50),
    manyJars: allOf([
        conditionHasResource('yeast', 100),
        conditionHasUpgrade('fasterYeast'),
    ]),
    vacation: conditionHasResource('money', 100),

    ///////////////////////////////

    unlockHardWork: conditionHasResource('knowledge', 5),
    unlockOvertime: conditionHasResource('knowledge', 100),
    unlockSales: conditionHasResource('knowledge', 100),
    unlockStrike: conditionHasResource('knowledge', 150),
};

export const processUpgradesUnlocks = (state: Draft<GameState>): void => {
    for (let upgrade of upgrades) {
        if (upgradeUnlocks[upgrade](state)) {
            unlockUpgrade(state, upgrade);
        }
    }
};
