import './Buildings.css';
import { useAppSelector } from '../../app/hooks';
import { OwnedBuilding } from '../../core/buildings/buildingTypes';
import { buildingDisplayName, getBuildingData } from '../../core/buildings/buildingUtils';
import { CurrentHero } from '../../core/hero';

import Card from 'react-bootstrap/Card';
import { ResourceProductionTableComponent } from '../shared/ResourceProductionTable';
import { BuildingBuyUpgradeButtonsComponent } from './BuildingBuyUpgradeButtons';
import { RootState } from '../../app/store';
import { BuildingInteractionButtons } from './BuildingInteractionButtons';
import { BuildingIcon } from './BuildingIcon';
import { getUpgradeData } from '../../core/upgrades/upgradeUtils';
import { isUnlockedAndVisible } from '../../utils/lock';
import { TemplateStringComponent } from '../shared/TemplateStringComponent';
import { hasBuildingTrait, hasTrait } from '../../core/buffs/buffUtils';

export type BuildingComponentProps = {
    building: OwnedBuilding;
    hero?: CurrentHero;
};

export const BuildingComponent = ({ building, hero }: BuildingComponentProps) => {
    const state = useAppSelector((state: RootState) => state);
    const upgrades = useAppSelector((state: RootState) => state.upgrades);
    const buildingProductionCache = useAppSelector((state: RootState) => state.cache.productionPerSecond.buildings);
    const allRps = buildingProductionCache.find(item => item.id === building.id)?.resources ?? [];

    const buildingData = getBuildingData(building.type);
    console.log(building);
    const buildingDescription = buildingData.description;
    const buildingHasGenerator = !!buildingData.generator && building.amount > 0;
    const isUniqueBuilding = buildingData.maxAmount === 1;
    const buildingHasDynamicDescrition = !!buildingData.dynamicDescription;
    const buildingIsStriking = hasBuildingTrait(state, building.id, 'strike');

    const buildingUpgrade = upgrades.find(upgrade => {
        const effect = getUpgradeData(upgrade.id).effect;
        return effect.type === 'buildingUpgrade' && effect.target === building.type && isUnlockedAndVisible(upgrade);
    });
    const hasUpgrade = !!buildingUpgrade;

    const expectButtons = !!buildingData.interaction || !!buildingData.heroInteraction || buildingData.maxAmount !== 1 || hasUpgrade;

    return (
        <Card
            className='Card'
            border={hero ? 'primary' : undefined}
        >
            <Card.Header className='CardHeader'>
                <div className='left'>
                    <BuildingIcon size='medium' includeName={false} building={building.id} />
                </div>
                <div className='middle'>
                    {buildingDisplayName(building)}
                </div>
                <div className='right'>
                    {!isUniqueBuilding && <>{building.amount}</>}
                    {/* {(buildingData.maxAmount ?? 0) > 1 && <span className='maxAmount'>/{buildingData.maxAmount}</span>} */}
                </div>
            </Card.Header>
            <Card.Body className='CardBody'>
                {buildingDescription}
                {buildingHasDynamicDescrition && <TemplateStringComponent content={buildingData.dynamicDescription!(state)} />}
                {(buildingHasGenerator && !buildingIsStriking) && <ResourceProductionTableComponent showHeaders showPerSeconds resourceAmounts={allRps} />}
                {buildingIsStriking && <div className='BuildingStrike'>On Strike!</div>}
            </Card.Body>
            {expectButtons && <hr className='CardBodySeparator' />}
            <div className='CardButtons'>
                <BuildingInteractionButtons building={building} hero={hero} />
                <BuildingBuyUpgradeButtonsComponent building={building} />
            </div>
        </Card >
    );
};
