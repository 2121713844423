import { ThemeProvider } from 'react-bootstrap';
import './App.scss';
import { Game } from './ui/Game';
import { Main } from './ui/interactions/Main';
import { TopBar } from './ui/resources/TopBar';
import { RightPanel } from './ui/RightPanel';
import { GameTooltip } from './ui/tooltips/GameTooltip';

function App() {
  return (
    <ThemeProvider >
      <div className="App" data-bs-theme="dark">
        <Game />
        <div className="MainContainer">
          <div className="TopBar">
            <TopBar />
          </div>
          <div className="Main">
            <Main />
          </div>
        </div>
        <div className="RightPanel">
          <RightPanel />
        </div>
        <GameTooltip />
      </div>
    </ThemeProvider>
  );
}

export default App;
