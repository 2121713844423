import './TopBar.css'
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { isUnlockedAndVisible } from '../../utils/lock';
import { ResourceComponent } from './ResourceComponent';
import { resourceGroups } from '../../core/resources/resourceTypes';

export function TopBar() {
    const resources = useAppSelector((state: RootState) => state.resources);

    const resourcesArray = resourceGroups.map(g => resources[g]);
    const unlockedResources = resourcesArray.filter(entry => isUnlockedAndVisible(entry));

    return (
        <div className="HorizonalContainer">
            {unlockedResources.map((entry) => {
                return <ResourceComponent resource={entry} key={entry.id} />;
            })}
        </div>
    );
}
