import { Draft } from "@reduxjs/toolkit";
import { GameState } from "../gamestate";

export type AnyMutatingAction = (state: Draft<GameState>) => void;
export type AnyAmountResolver = (state: GameState) => number;
export type AnyTextResolver = (state: GameState) => string;

export type EntityName = {
    name: string,
    plural: string
};

export const constName = (name: string): EntityName => {
    return {
        name: name,
        plural: name,
    };
};

export const simpleName = (name: string): EntityName => {
    return {
        name: name,
        plural: name + 's',
    };
};

export const entityName = (name: string, plural: string): EntityName => {
    return {
        name: name,
        plural: plural,
    };
};
