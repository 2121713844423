import { Draft } from "@reduxjs/toolkit";
import { Unlockable } from "../../utils/lock";
import { GameState } from "../gamestate";
import { TemplateStringMaker } from "../shared/templateString";

export const tier1Spells = [
    'hardwork',
    'overtime',
    'sales',
    'strike',
    // 'omnipresence',
] as const;

export const spellTypes = [...tier1Spells] as const;

export type Tier1SpellType = typeof tier1Spells[number];
export type SpellType = typeof spellTypes[number];

export type SpellEffectTemporaryBuff = {
    type: 'temporary';
    activate: (state: Draft<GameState>) => void;
    deactivate: (state: Draft<GameState>) => void;
    duration: number;
}
export type SpellEffectInstant = {
    type: 'immediate';
    activate: (state: Draft<GameState>) => void;
}
export type SpellEffect = SpellEffectTemporaryBuff | SpellEffectInstant;

// All times are expressed in ticks
export type Spell = {
    name: string;
    getDescription: TemplateStringMaker;
    effect: SpellEffect;
    cooldown: number; // The time it takes for the spell to be available again
    recovery: number; // The time it takes for the hero to be able to perform actions/cast more spells again
};

export type SpellStatusInactive = {
    active: false;
};
export type SpellStatusActive = {
    active: true;
    duration: number;
};
export type SpellStatus = SpellStatusInactive | SpellStatusActive;

export type AvailableSpell = {
    id: SpellType;
    status: SpellStatus;
    cooldown: number;
    unlocked: Unlockable;
}

export type AvailableSpells = {
    [K in SpellType]: AvailableSpell;
}
