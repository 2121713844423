import { BuildingGroup } from "../buildings/buildingTypes";
import { ResourceAmount, ResourceType, ResourceGroup } from "../resources/resourceTypes";

export type EntityProductionCache<T> = {
    id: T;
    resources: ResourceAmount[];
};

export type ResourceGroupToTypeMapping = {
    [K in ResourceGroup]: ResourceType;
}

export type GameCache = {
    resourcePerSecond: ResourceAmount[];
    productionPerSecond: {
        buildings: EntityProductionCache<BuildingGroup>[];
        buildingsForSingleAmount: EntityProductionCache<BuildingGroup>[];
        interactions: EntityProductionCache<BuildingGroup>[];
        hero: ResourceAmount[];
    };
};

export const emptyCache = (): GameCache => {
    return {
        resourcePerSecond: [],
        productionPerSecond: {
            buildings: [],
            buildingsForSingleAmount: [],
            interactions: [],
            hero: [],
        },
    };
};
