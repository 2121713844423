import { Unlockable } from "../../utils/lock";

export const resourceGroups = [
    'wheat',
    'milk',
    'egg',
    'water',
    'flour',
    'butter',
    'yeast',
    'anypastry',
    'money',
    'coal',
    'knowledge',
    'steam',
    'cacao',
    'sugar',
    'chocolate',
] as const;

export const pastryTiers = [
    'pastry',
    'goldenpastry',
    'protocroissant',
    'croissant',
] as const;

export const resourceTypes = [
    'wheat',
    'milk',
    'egg',
    'water',
    'flour',
    'butter',
    'yeast',
    'money',
    ...pastryTiers,
    'coal',
    'knowledge',
    'steam',
    'cacao',
    'sugar',
    'chocolate',
] as const;

export type PastryType = typeof pastryTiers[number];
export type ResourceGroup = typeof resourceGroups[number];
export type ResourceType = typeof resourceTypes[number];

export type Resource = {
    fullName: string;
    shortName: string;
    tier: number;
}

export type ResourceAmount = {
    id: ResourceGroup;
    amount: number;
}

export type OwnedResource = {
    id: ResourceGroup;
    type: ResourceType;
    amount: number;
    unlocked: Unlockable;
}

export type OwnedResources = {
    [K in ResourceGroup]: OwnedResource;
};
