import './Hero.css';
import { Button } from 'react-bootstrap';
import { AvailableSpell } from '../../core/spells/spellTypes';
import { getSpellData } from '../../core/spells/spellUtils';
import { gameTooltipId, spellTooltipTarget } from '../tooltips/tooltipData';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { castSpell } from '../../core/game';
import { SpellIcon } from './SpellIcon';
import { RootState } from '../../app/store';


export type SpellComponentProps = {
    spell: AvailableSpell;
};

export const SpellComponent = ({ spell }: SpellComponentProps) => {
    const dispatch = useAppDispatch();
    const hero = useAppSelector((state: RootState) => state.hero);
    const isOnCooldown = spell.cooldown > 0;
    const durationLeft = spell.status.active ? spell.status.duration : 0;
    const isHeroRecovering = !!hero && hero.recovery > 0;
    const disabled = isOnCooldown || isHeroRecovering;

    const onClick = () => dispatch(castSpell(spell.id));

    const getVariant = (): string => {
        if (durationLeft > 0) {
            return 'success';
        } else if (isOnCooldown) {
            return 'secondary';
        }
        return 'info';
    };

    return (
        <span
            data-tooltip-id={gameTooltipId}
            data-tooltip-content={spellTooltipTarget(spell.id, disabled)}
            data-tooltip-place='left'>
            <Button
                disabled={disabled}
                onClick={onClick}
                variant={getVariant()}
                className='SpellButton'
            >
                <SpellIcon spell={spell.id} size='small' includeName />
            </Button>
        </span>

    );
};
