import './GameTooltip.css';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { buildingExpectedProductionAfterUpgrade } from '../../core/buildings/buildingUtils';
import { Upgrade, UpgradeType } from '../../core/upgrades/upgradeTypes';
import { getUpgradeData } from '../../core/upgrades/upgradeUtils';
import { ResourceProductionTableComponent } from '../shared/ResourceProductionTable';
import { TemplateStringComponent } from '../shared/TemplateStringComponent';

type GameTooltipUpgradeProps = {
    upgradeType: UpgradeType;
};

export const GameTooltipUpgrade = ({ upgradeType }: GameTooltipUpgradeProps) => {
    const upgradeData = getUpgradeData(upgradeType);

    if (upgradeData.effect.type === 'buildingUpgrade') {
        return <GameTooltipBuildingUpgrade upgradeData={upgradeData} />
    } else {
        return (
            <span className='TooltipUpgradeDescription'>
                <TemplateStringComponent content={upgradeData.description} />
            </span>
        );
    }
};


type GameTooltipBuildingUpgradeProps = {
    upgradeData: Upgrade;
};

const GameTooltipBuildingUpgrade = ({ upgradeData }: GameTooltipBuildingUpgradeProps) => {
    const state = useAppSelector((state: RootState) => state);
    const expectedProduction = buildingExpectedProductionAfterUpgrade(state, upgradeData);

    return (
        <>
            <div className='TooltipUpgradeDescription'>
                <TemplateStringComponent content={upgradeData.description} />
            </div>
            {expectedProduction.length > 0 && (<>
                <div className='TooltipUpgradeProductionTitle'>Upgrading will replace production with:</div>
                <ResourceProductionTableComponent showHeaders={false} showPerSeconds resourceAmounts={expectedProduction} />
            </>)}
        </>
    );
};
