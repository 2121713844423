import './InteractionFeedback.css'
import { useInteractionFeedbackContext } from './InteractionFeedbackContext';
import { CSSTransition } from 'react-transition-group';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { OwnedBuilding } from '../../../core/buildings/buildingTypes';
import { ResourceProductionResourceAmountComponent } from '../../shared/ResourceProductionTable';

type FeedbackTooltipInteractableProps = {
    building: OwnedBuilding;
}

const FeedbackTooltipInteractable = ({ building }: FeedbackTooltipInteractableProps) => {
    const interactionsProductionCache = useAppSelector((state: RootState) => state.cache.productionPerSecond.interactions);
    const allRpi = interactionsProductionCache.find(item => item.id === building.id)?.resources ?? [];

    return (
        <>
            {allRpi.map(rpi => {
                return <ResourceProductionResourceAmountComponent
                    key={rpi.id}
                    showPerSeconds={false}
                    resourceAmount={rpi} />;
            })}

        </>
    );
}

export const InteractionFeedbackComponent = () => {
    const feedbackElements = useInteractionFeedbackContext().interactions;

    return (
        <div className='FeedbackContainer'>
            {feedbackElements.map(feedback => {
                return (
                    <CSSTransition
                        in
                        appear
                        timeout={1000}
                        classNames='feedback'
                        key={feedback.uuid}
                    >
                        <div className='feedback' style={{ bottom: `calc(100vh - ${feedback.y + 16}px)`, left: feedback.x }}>
                            {feedback.succeeded ?
                                (<FeedbackTooltipInteractable building={feedback.target} />) :
                                (<span>Cannot afford</span>)}

                        </div>
                    </CSSTransition>

                );
            })}
        </div>
    );
};
