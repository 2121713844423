import './ResourceProductionTable.css';
import { ResourceAmount } from '../../core/resources/resourceTypes';

import { Col, Container, Row } from 'react-bootstrap';
import { NumberFormatter } from '../../utils/formatter';
import { ResourceIcon } from '../resources/ResourceIcon';

export type ResourceProductionResourceAmountProps = {
    showPerSeconds: boolean;
    resourceAmount: ResourceAmount | undefined;
}

export const ResourceProductionResourceAmountComponent = ({ showPerSeconds, resourceAmount }: ResourceProductionResourceAmountProps) => {
    if (resourceAmount === undefined) {
        return <></>;
    }

    const amountDisplayString = NumberFormatter.formatRelative(resourceAmount.amount);
    return (
        <div className='ResourceTableEntry'>
            <span className={resourceAmount.amount > 0 ? 'positive' : 'negative'}>
                {amountDisplayString}
                {showPerSeconds && <span className='ResourceTablePerSecond'>/s</span>}
            </span>
            <ResourceIcon size='small' includeName resource={resourceAmount.id} />
        </div>
    );
}

type ResourceProductionTableData = {
    action: string;
    data: ResourceAmount[];
}

export type ResourceProductionTableProps = {
    showHeaders: boolean;
    showPerSeconds: boolean;
    resourceAmounts: ResourceAmount[];
};

export const ResourceProductionTableComponent = ({ showHeaders, showPerSeconds, resourceAmounts }: ResourceProductionTableProps) => {
    const negativeRps = resourceAmounts.filter(rps => rps.amount < 0);
    const positiveRps = resourceAmounts.filter(rps => rps.amount >= 0);
    const rows = Math.max(negativeRps.length, positiveRps.length);

    const all: ResourceProductionTableData[] = [
        { action: "Consumed", data: negativeRps },
        { action: "Produced", data: positiveRps },
    ].filter(item => item.data.length);

    return (
        <Container className='ResourceTable'>
            {showHeaders && (
                <Row>
                    {all.map(item => {
                        return <Col className='ResourceTableLabel'>{item.action}:</Col>
                    })}
                </Row>
            )}
            {[...Array(rows)].map((_, i) => {
                return (
                    <Row key={i}>
                        {all.map(item => {
                            return (
                                <Col className='ResourceTableValue'>
                                    <ResourceProductionResourceAmountComponent showPerSeconds={showPerSeconds} resourceAmount={item.data[i]} />
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
        </Container>
    )
};