import { Draft } from "@reduxjs/toolkit";
import { GameState, getBuilding, getResource, getSpell, getUpgrade } from "../gamestate";
import { BuildingGroup, BuildingType } from "../buildings/buildingTypes";
import { ResourceGroup } from "../resources/resourceTypes";
import { setUnlockedAndHidden, isUnlocked, setUnlocked } from "../../utils/lock";
import { UpgradeType } from "../upgrades/upgradeTypes";
import { SpellType } from "../spells/spellTypes";
import { buildingDefaultTypeForGroup } from "../buildings/buildingUtils";

export type UnlockCondition = (state: Draft<GameState>) => boolean;

export function conditionNever(): UnlockCondition {
    return (): boolean => {
        return false;
    };
}

export function conditionAlways(): UnlockCondition {
    return (): boolean => {
        return true;
    };
}

export function conditionHasResource(type: ResourceGroup, amount: number): UnlockCondition {
    return (state: Draft<GameState>): boolean => {
        return getResource(state, type).amount >= amount;
    };
}

export function conditionHasBuilding(type: BuildingGroup, amount: number): UnlockCondition {
    return (state: Draft<GameState>): boolean => {
        return getBuilding(state, type).amount >= amount;
    };
}

export function conditionHasUpgrade(type: UpgradeType): UnlockCondition {
    return (state: Draft<GameState>): boolean => {
        return getUpgrade(state, type).owned;
    };
}

export function allOf(conditions: UnlockCondition[]): UnlockCondition {
    return (state: Draft<GameState>): boolean => {
        for (let c of conditions) {
            if (!c(state)) {
                return false;
            }
        }
        return true;
    };
}


export const unlockResource = (state: Draft<GameState>, resource: ResourceGroup): void => {
    const entity = getResource(state, resource);
    setUnlocked(entity);
};

export const unlockHiddenResource = (state: Draft<GameState>, resource: ResourceGroup): void => {
    const entity = getResource(state, resource);
    setUnlockedAndHidden(entity);
};

export const unlockAndOwnBuilding = (state: Draft<GameState>, building: BuildingGroup): void => {
    const entity = getBuilding(state, building);
    if (!isUnlocked(entity)) {
        setUnlocked(entity);

        // TODO: This should not be necessary once I fix the persistence
        entity.id = building;
        entity.type = buildingDefaultTypeForGroup(building);

        entity.amount = 1;
    }
};

export const unlockUpgrade = (state: Draft<GameState>, upgrade: UpgradeType): void => {
    const entity = getUpgrade(state, upgrade);
    setUnlocked(entity);
};

export const unlockSpell = (state: Draft<GameState>, spell: SpellType): void => {
    const entity = getSpell(state, spell);
    setUnlocked(entity);
};

