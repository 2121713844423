import { Draft } from "@reduxjs/toolkit";
import { OwnedResource } from "../core/resources/resourceTypes";
import { OwnedBuilding } from "../core/buildings/buildingTypes";
import { AvailableUpgrade } from "../core/upgrades/upgradeTypes";
import { AvailableSpell } from "../core/spells/spellTypes";

export enum Unlockable {
    Locked, // Not visible yet, will be unlocked eventually
    Unlocked, // Visible and purchasable/owned
    Hidden, // Unlocked but not shown
};

export type AnyUnlockable = OwnedResource | OwnedBuilding | AvailableUpgrade | AvailableSpell;
export type AnyUnlockableMutable = Draft<AnyUnlockable>

export const UnlockedIf = (condition: boolean): Unlockable => {
    return condition ? Unlockable.Unlocked : Unlockable.Locked;
};

export function isUnlocked(entity: AnyUnlockable): boolean {
    return entity.unlocked !== Unlockable.Locked;
};

export function isUnlockedAndVisible(entity: AnyUnlockable): boolean {
    return entity.unlocked === Unlockable.Unlocked;
};

export function setUnlocked(entity: AnyUnlockableMutable) {
    if (entity.unlocked === Unlockable.Locked) {
        entity.unlocked = Unlockable.Unlocked;
    }
};

export function setUnlockedAndHidden(entity: AnyUnlockableMutable) {
    if (entity.unlocked === Unlockable.Locked) {
        entity.unlocked = Unlockable.Hidden;
    }
};
