import './Main.css'
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { InteractionFeedbackComponent } from './feedback/InteractionFeedbackComponent';
import { InteractionFeedbackProvider } from './feedback/InteractionFeedbackProvider';
import { buildingGroups } from '../../core/buildings/buildingTypes';
import { BuildingComponent } from '../buildings/BuildingComponent';
import { Col, Container, Row } from 'react-bootstrap';
import { isUnlocked } from '../../utils/lock';
import { DebugPanelComponent } from '../DebugPanel';

export function Main() {
    const buildings = useAppSelector((state: RootState) => state.buildings);
    const hero = useAppSelector((state: RootState) => state.hero);

    const orderedBuildings = buildingGroups.map(t => buildings[t]);

    return (
        <InteractionFeedbackProvider>
            <div className="Background">
                <Container className='EntitiesContainer'>
                    <Row>
                        {orderedBuildings.filter((entry) => isUnlocked(entry)).map((entry) => {
                            return (
                                <Col key={entry.id} xxl={2} xl={3} lg={4} md={6} sm={12}>
                                    <BuildingComponent
                                        building={entry}
                                        hero={hero?.target === entry.id ? hero : undefined}
                                    />
                                </Col>
                            );
                        })}
                        <Col xxl={2} xl={3} lg={4} md={6} sm={12}>
                            <DebugPanelComponent />
                        </Col>
                    </Row>
                </Container>
                <InteractionFeedbackComponent />
            </div>
        </InteractionFeedbackProvider>
    );
}
