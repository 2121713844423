import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { BuildingGroup, HeroInteraction } from '../../core/buildings/buildingTypes';
import { buildingDisplayName, getBuildingData } from '../../core/buildings/buildingUtils';
import { DurationValue } from '../shared/DurationValue';
import { ResourceProductionTableComponent } from '../shared/ResourceProductionTable';

type GameTooltipBuildingHeroProps = {
    buildingGroup: BuildingGroup;
};

export const GameTooltipBuildingHero = ({ buildingGroup }: GameTooltipBuildingHeroProps) => {
    const building = useAppSelector((state: RootState) => state.buildings[buildingGroup]);
    const buildingInteractionCache = useAppSelector((state: RootState) => state.cache.productionPerSecond.interactions);
    const hero = useAppSelector((state: RootState) => state.hero);

    const getHeroInteractionContent = (interacion: HeroInteraction): JSX.Element => {
        switch (interacion.type) {
            case 'click':
                const allRps = buildingInteractionCache.find(item => item.id === building.id)?.resources ?? [];
                return (
                    <>
                        The hero interacts with this building, generating:
                        <ResourceProductionTableComponent showHeaders={false} showPerSeconds resourceAmounts={allRps} />
                    </>
                );
            case 'resources':
                return <>!</>;
            case 'custom':
                return <>{interacion.tooltipDescription}</>;
        }
    }

    if (hero) {
        if (hero.target === building.id) {
            const info = getBuildingData(building.type);
            const isHeroRecovering = hero.recovery > 0;
            return (
                <>
                    {info.heroInteraction && getHeroInteractionContent(info.heroInteraction)}
                    {isHeroRecovering && (
                        <div className='HeroRecovering'>
                            The hero is still recovering: <DurationValue duration={hero.recovery} />
                        </div>
                    )}
                </>
            );
        } else {
            return <>Move the hero to {buildingDisplayName(building)}</>;
        }
    }
    return <></>;
}
