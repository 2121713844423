import { Draft } from "@reduxjs/toolkit";
import { UnlockedIf, isUnlocked } from "../../utils/lock";
import { GameState, getSpell } from "../gamestate";
import { AvailableSpell, AvailableSpells, Spell, SpellEffectInstant, SpellEffectTemporaryBuff, SpellType, spellTypes } from "./spellTypes";
import { Multiplier, MultiplierType, StaticMultiplier } from "../multipliers/multiplierTypes";
import { BuffBuildingTraits, BuffIdentifier, BuffMultipliers, BuffSpecial, BuffTrait } from "../buffs/buffTypes";
import { allSpellData } from "./spellData";
import { BuildingGroup } from "../buildings/buildingTypes";

export const getSpellData = (type: SpellType): Spell => {
    return allSpellData[type];
};

const makeAvailableSpell = (type: SpellType): AvailableSpell => {
    return {
        id: type,
        status: {
            active: false
        },
        unlocked: UnlockedIf(false),
        cooldown: 0,
    };
}

export const initialAvailableSpells = (): AvailableSpells => {
    const object = spellTypes.reduce((obj, item) => {
        return {
            ...obj,
            [item]: makeAvailableSpell(item),
        };
    }, {});
    return object as AvailableSpells;
}

export function makeInstantSpellEffect(activate: (state: Draft<GameState>) => void): SpellEffectInstant {
    return {
        type: 'immediate',
        activate: activate,
    };
};

export type SpellEffectTemporaryBuffData = Omit<SpellEffectTemporaryBuff, 'type'>;
export function makeTemporaryBuffSpellEffect(data: SpellEffectTemporaryBuffData): SpellEffectTemporaryBuff {
    return {
        type: 'temporary',
        ...data
    };
};

export function makeMultiplierBuff(identifier: BuffIdentifier, multipliers: Multiplier[]): BuffMultipliers {
    return {
        type: 'multipliers',
        identifier: identifier,
        multipliers: multipliers,
    };
};

export function makeStaticSpellMultiplier(type: MultiplierType, amount: number): StaticMultiplier {
    return {
        dynamic: false,
        type: type,
        amount: amount,
    };
};

export function makeSpecialBuff(identifier: BuffIdentifier, traits: BuffTrait[]): BuffSpecial {
    return {
        type: 'special',
        identifier: identifier,
        traits: traits
    };
};

export function makeBuildingTraitBuff(identifier: BuffIdentifier, building: BuildingGroup, traits: BuffTrait[]): BuffBuildingTraits {
    return {
        type: 'building',
        identifier: identifier,
        building: building,
        traits: traits,
    };
};

export const spellTickUpdate = (state: Draft<GameState>, spellType: SpellType) => {
    const spell = getSpell(state, spellType);
    if (spell.status.active) {
        spell.status.duration--;
        if (spell.status.duration <= 0) {
            spell.status = { active: false };

            const info = getSpellData(spellType);
            if (info.effect.type === 'temporary') {
                info.effect.deactivate(state);
            }
        }
    }

    if (spell.cooldown > 0) {
        spell.cooldown--;
    }
};

export const canCastSpell = (state: GameState, spellType: SpellType): boolean => {
    const spell = getSpell(state, spellType);
    return isUnlocked(spell) &&
        spell.cooldown <= 0 &&
        !spell.status.active &&
        !!state.hero &&
        state.hero.recovery <= 0;

};

export const performCastSpell = (state: GameState, spellType: SpellType) => {
    const spell = getSpell(state, spellType);
    const spellData = getSpellData(spellType);

    spell.cooldown = spellData.cooldown;
    state.hero!.recovery = spellData.recovery;
    switch (spellData.effect.type) {
        case 'temporary':
            spell.status = {
                active: true,
                duration: spellData.effect.duration,
            };
            spellData.effect.activate(state);
            break;
        case 'immediate':
            spellData.effect.activate(state);
            break;
    }
};