import './Hero.css'
import { SpellType } from '../../core/spells/spellTypes';
import { getSpellData } from '../../core/spells/spellUtils';

export type SpellIconSize = 'medium' | 'small';
export type SpellIconProps = {
    spell: SpellType;
    size: SpellIconSize;
    includeName: boolean;
};

export const SpellIcon = ({ spell, size, includeName }: SpellIconProps) => {
    const spellName = getSpellData(spell).name;

    return (
        <span className='SpellIconContainer'>
            <img
                className={`SpellIcon ${size}`}
                alt={spellName}
                title={spellName}
                src={`./icons/spells/${spell}.png`}>
            </img>
            {includeName && <span className='SpellIconName'>{spellName}</span>}
        </span>
    );
};
