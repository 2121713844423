import { Draft } from "@reduxjs/toolkit";
import { GameState } from "../gamestate";
import { Buff, BuffIdentifier, BuffTrait } from "./buffTypes";
import { BuildingGroup } from "../buildings/buildingTypes";

export const addBuff = (state: Draft<GameState>, buff: Buff): void => {
    addBuffs(state, [buff]);
};

export const addBuffs = (state: Draft<GameState>, buffs: Buff[]): void => {
    state.buffs = state.buffs.concat(buffs);
};

export const removeBuff = (state: Draft<GameState>, buffIdentifier: BuffIdentifier): void => {
    removeBuffs(state, [buffIdentifier]);
};

export const removeBuffs = (state: Draft<GameState>, buffIdentifiers: BuffIdentifier[]): void => {
    state.buffs = state.buffs.filter(buff => !buffIdentifiers.includes(buff.identifier));
};


export const hasTrait = (state: GameState, trait: BuffTrait): boolean => {
    for (let buff of state.buffs) {
        if (buff.type === 'special') {
            if (buff.traits.includes(trait)) {
                return true;
            }
        }
    }
    return false;
};

export const hasBuildingTrait = (state: GameState, building: BuildingGroup, trait: BuffTrait): boolean => {
    for (let buff of state.buffs) {
        if (buff.type === 'building') {
            if (buff.building === building) {
                if (buff.traits.includes(trait)) {
                    return true;
                }
            }
        }
    }
    return false;
};