import './RightPanel.css'
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { UpgradesContainerComponent } from './upgrades/UpgradesContainerComponent';
import { HeroPanel } from './hero/HeroPanel';

export function RightPanel() {
    const hasHero = useAppSelector((state: RootState) => !!state.hero)

    return (
        <div className='RightPanelContainer'>
            <div className='UpgradePanelContainer'>
                <UpgradesContainerComponent />
            </div>
            {hasHero && (
                <div className='HeroPanelContainer'>
                    <HeroPanel />
                </div>
            )}
        </div>
    );
}
