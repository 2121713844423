import './Buildings.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { OwnedBuilding } from '../../core/buildings/buildingTypes';
import { canHeroInteractWithBuilding, canInteractWithBuilding, willInteractionSucceed } from '../../core/buildings/buildingUtils';
import { CurrentHero } from '../../core/hero';

import Button from 'react-bootstrap/Button';
import { buildingHeroInteractTooltipTarget, buildingInteractTooltipTarget, gameTooltipId } from '../tooltips/tooltipData';
import { useInteractionFeedbackContext } from '../interactions/feedback/InteractionFeedbackContext';
import { HeroInteractionFeedbackComponent } from '../interactions/feedback/HeroInteractionFeedbackComponent';
import { onBuildingUserInteraction, onMoveHero } from '../../core/game';
import { hasTrait } from '../../core/buffs/buffUtils';
import { HeroImage } from '../hero/HeroImage';

export type BuildingInteractionButtonsProps = {
    building: OwnedBuilding;
    hero?: CurrentHero;
};

export const BuildingInteractionButtons = ({ building, hero }: BuildingInteractionButtonsProps) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state: RootState) => state);
    const hasHero = useAppSelector((state: RootState) => !!state.hero);

    const canUserInteract = canInteractWithBuilding(building);
    const areClickDisabled = hasTrait(state, 'manual-interaction-disabled');
    const canHeroInteract = hasHero && canHeroInteractWithBuilding(building);
    const queueInteractionAction = useInteractionFeedbackContext().queueInteraction;
    const willHeroInteract = !!hero && hero.recovery <= 0;

    const onClickInteract = (event: React.MouseEvent<HTMLElement>): void => {
        dispatch(onBuildingUserInteraction(building.id));
        const success = willInteractionSucceed(state, building);
        queueInteractionAction(building, event.clientX, event.clientY, true, success);
    };

    const onClickHero = (event: React.MouseEvent<HTMLElement>): void => {
        dispatch(onMoveHero(building.id));
    };

    if (canUserInteract || canHeroInteract) {
        return (
            <>
                <div className='InteractionButtonsContainer'>
                    {canUserInteract && (
                        <span
                            data-tooltip-id={gameTooltipId}
                            data-tooltip-content={buildingInteractTooltipTarget(building.id, areClickDisabled)}
                            data-tooltip-place='right-end'
                            className='InteractionButtonContainer'>
                            <Button
                                size='sm'
                                variant='secondary'
                                onClick={onClickInteract}
                                disabled={areClickDisabled}
                                className='InteractionButton'>
                                Interact
                            </Button>
                        </span>
                    )}
                    {canHeroInteract && (
                        <HeroInteractionFeedbackComponent enabled={willHeroInteract} fullsize={!canUserInteract}>
                            <span
                                data-tooltip-id={gameTooltipId}
                                data-tooltip-content={buildingHeroInteractTooltipTarget(building.id, !!hero && !willHeroInteract)}
                                data-tooltip-place='right-end'
                                className={`HeroButtonContainer${canUserInteract ? '' : ' fullsize'}`}>
                                <Button
                                    size='sm'
                                    variant={willHeroInteract ? 'primary' : 'secondary'}
                                    onClick={onClickHero}
                                    className={`HeroButton${canUserInteract ? '' : ' fullsize'}`}>
                                    {canUserInteract ? <></> : <>{hero ? `Hero is ${willHeroInteract ? 'interacting' : 'recovering'} ` : 'Move hero '}</>}
                                    {hero ?
                                        <HeroImage size='small' showTitle /> :
                                        <img className='HeroMoveIcon' alt='move hero here' src='./icons/hero/move-hero.png' />}
                                </Button>
                            </span>
                        </HeroInteractionFeedbackComponent>
                    )}
                </div>
            </>
        );
    } else {
        return <></>;
    }
};
