import { UnlockedIf } from "../../utils/lock";
import { assertUnreachable } from "../../utils/never";
import { GameState } from "../gamestate";
import { allResources } from "./resourceData";
import { OwnedResource, Resource, ResourceType, ResourceGroup, OwnedResources, resourceGroups, PastryType } from "./resourceTypes";

export const getResourceData = (type: ResourceType): Resource => {
    return allResources[type];
};

export const resourceDefaultTypeForGroup = (type: ResourceGroup): ResourceType => {
    switch (type) {
        case 'wheat':
        case 'milk':
        case 'flour':
        case 'butter':
        case 'water':
        case 'egg':
        case 'money':
        case 'coal':
        case 'knowledge':
        case 'yeast':
        case 'steam':
        case 'cacao':
        case 'sugar':
        case 'chocolate':
            return type as ResourceType;
        case 'anypastry':
            return 'pastry';
        default:
            assertUnreachable(type);
    }
};

const makeOwnedResource = (group: ResourceGroup): OwnedResource => {
    return {
        id: group,
        type: resourceDefaultTypeForGroup(group),
        amount: 0,
        unlocked: UnlockedIf(group === 'wheat'),
    };
};

export const initialOwnedResources = (): OwnedResources => {
    const object = resourceGroups.reduce((obj, item) => {
        return {
            ...obj,
            [item]: makeOwnedResource(item),
        };
    }, {});
    return object as OwnedResources;
};

export function getResourceTier(type: ResourceType): number {
    return getResourceData(type).tier;
};

export function getCurrentPastryType(state: GameState): PastryType {
    return state.resources.anypastry.type as PastryType;
}
