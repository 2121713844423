import './shared.css'
import { TemplateString } from '../../core/shared/templateString';
import { ResourceIcon } from '../resources/ResourceIcon';
import { DurationValue } from './DurationValue';
import { NumberFormatter } from '../../utils/formatter';
import { BuildingIcon } from '../buildings/BuildingIcon';
import { SpellIcon } from '../hero/SpellIcon';

export type TemplateStringComponentProps = {
    content: TemplateString;
};

export const TemplateStringComponent = ({ content }: TemplateStringComponentProps) => {
    return (
        <span className='TemplateStringContainer'>
            {content.parts.map((part) => {
                return (
                    <span className={part.kind}>
                        {((): JSX.Element => {
                            switch (part.kind) {
                                case 'plain':
                                    return <>{part.value}</>;
                                case 'duration':
                                    return <DurationValue duration={part.value} />;
                                case 'multiplier':
                                    return <>{NumberFormatter.formatStatic(part.value)}x</>;
                                case 'resourceAmount':
                                    return <>
                                        {part.amount}
                                        <ResourceIcon resource={part.resource} size='small' includeName />
                                    </>;
                                case 'building':
                                    return <BuildingIcon building={part.building} size='small' includeName />
                                case 'resource':
                                    return <ResourceIcon resource={part.resource} size='small' includeName />;
                                case 'spell':
                                    return <SpellIcon spell={part.spell} size='small' includeName />;
                                case 'highlight':
                                    return <>{part.value}</>;
                            }
                        })()}
                    </span>
                );
            })}
        </span>
    );
};
