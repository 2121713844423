import './Hero.css';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { isUnlockedAndVisible } from '../../utils/lock';
import { spellTypes } from '../../core/spells/spellTypes';
import { SpellComponent } from './SpellComponent';
import { HeroImage } from './HeroImage';
import { DurationValue } from '../shared/DurationValue';
import { BuildingIcon } from '../buildings/BuildingIcon';
import { gameTooltipId, heroTooltipTarget } from '../tooltips/tooltipData';

function HeroAction() {
    const hero = useAppSelector((state: RootState) => state.hero);

    if (!hero) {
        return <></>;
    }

    if (hero.recovery > 0) {
        return (
            <div>
                <div className='HeroActionTargetTitle'>Recovering...</div>
                <DurationValue duration={hero.recovery} />
            </div>
        );
    } else if (hero.target) {
        return (
            <span className='HeroActionTargetBuilding'>
                <div className='HeroActionTargetTitle'>Interacting with</div>
                <BuildingIcon building={hero.target} size='small' includeName={true} />
            </span>
        );
    } else {
        return <>Idle</>;
    }
};

export function HeroPanel() {
    const spells = useAppSelector((state: RootState) => state.spells);
    const spellsArray = spellTypes.map(g => spells[g]);
    const unlockedSpells = spellsArray.filter(entry => isUnlockedAndVisible(entry));

    return (
        <div className='HeroPanelSeparatorContainer'>
            <hr className='HeroPanelSeparator' />
            <div className='HeroPanel'>
                <div className='HeroPanelColumn'>
                    {unlockedSpells.length > 0 && <>
                        <span className='SpellsTitle'>Abilities</span>
                        <div className='SpellsContainer'>
                            {unlockedSpells.map((entry) => {
                                return (<SpellComponent spell={entry} key={entry.id} />);
                            })}
                        </div>
                    </>}
                </div>
                <div className='HeroPanelColumn'>
                    <div
                        className='HeroPortrait'
                        data-tooltip-id={gameTooltipId}
                        data-tooltip-content={heroTooltipTarget(false)}
                        data-tooltip-place='left'>
                        <HeroImage size='full' showTitle={false} />
                    </div>
                    <HeroAction />
                </div>
            </div>
        </div>
    );
}
