import React, { ReactElement } from 'react';

import './ResourceComponent.css'
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { getResourceData } from '../../core/resources/resourceUtils';
import { NumberFormatter } from '../../utils/formatter';
import { OwnedResource } from '../../core/resources/resourceTypes';
import { ResourceIcon } from './ResourceIcon';
import { isAlmostZero } from '../../utils/numberUtils';

export type ResourceComponentProps = {
    resource: OwnedResource;
};

export const ResourceComponent = ({ resource }: ResourceComponentProps) => {
    const resourcePerSecond = useAppSelector((state: RootState) => state.cache.resourcePerSecond);
    const colorClass = (rps: number): string => {
        if (isAlmostZero(rps)) {
            return '';
        }
        return rps > 0 ? 'positive' : 'negative';
    };
    const getRps = (resource: OwnedResource): ReactElement => {
        const rps = resourcePerSecond.find(res => res.id === resource.id)?.amount ?? 0;
        return (
            <div className={colorClass(rps)}>{NumberFormatter.formatRelative(rps)}<span className='ResourceEntryGrowthPerSecond'>/s</span></div>
        );
    };

    return (
        <div className="ResourceEntry">
            <div className="ResourceEntryHeader">
                <ResourceIcon size='medium' includeName={false} resource={resource.id} />
                <span className='ResourceEntryName'>{getResourceData(resource.type).fullName}</span>
            </div>
            <div>{NumberFormatter.format(resource.amount)}</div>
            <div className="ResourceEntryGrowth">{getRps(resource)}</div>
        </div >
    );
};
