import { Draft } from "@reduxjs/toolkit";
import { UnlockedIf, isUnlocked } from '../../utils/lock';
import { GameState, getBuilding, getResource } from '../gamestate';
import { getMultipliersForBuildingGenerator, getMultipliersForInteraction } from '../multipliers/multiplierUtils';
import { OwnedResources, ResourceAmount, ResourceGroup } from '../resources/resourceTypes';
import { allBuildings } from './buildingData';
import { Building, BuildingResourceGenerator, BuildingGroup, OwnedBuilding, BuildingType, OwnedBuildings, buildingGroups, BuildingsFlat, BuildingGroupToTypeBinding, BuildingTypeToGroupBinding, BuildingInteraction, HeroInteraction, AnyBuildingResourceGenerator } from './buildingTypes';
import { Upgrade } from "../upgrades/upgradeTypes";

const allBuildingsFlat: BuildingsFlat = ((): BuildingsFlat => {
    var res: any = {};
    for (let group of Object.keys(allBuildings)) {
        for (let type of Object.keys(allBuildings[group as BuildingGroup])) {
            res[type] = allBuildings[group as BuildingGroup][type as BuildingType];
        }
    }
    return res as BuildingsFlat;
})();

const buildingsGroupBindings: BuildingTypeToGroupBinding = ((): BuildingTypeToGroupBinding => {
    var res: any = {};
    for (let group of Object.keys(allBuildings)) {
        for (let type of Object.keys(allBuildings[group as BuildingGroup])) {
            res[type] = group as BuildingGroup;
        }
    }
    return res as BuildingTypeToGroupBinding;
})();

const buildingGroupToTypeBindings: BuildingGroupToTypeBinding = {
    farm: 'wheat',
    cows: 'milk',
    grindstone: 'grindstone',
    churn: 'churn',
    coop: 'coop',
    well: 'well',
    oven: 'oven',
    bakery: 'firstBakery',
    coal: 'coalMine',
    study: 'study',
    jar: 'jar',
    boiler: 'boiler',
    cacaotree: 'cacaotree',
    sugarcane: 'sugarcane',
    mixer: 'mixer',
};

export const getBuildingData = (type: BuildingType): Building => {
    return allBuildingsFlat[type];
};

export const buildingDisplayName = (building: OwnedBuilding): string => {
    const info = getBuildingData(building.type);
    return building.amount === 1 ? info.name.name : info.name.plural;
}

export const anyBuildingDisplayName = (building: Building, amount: number): string => {
    return amount === 1 ? building.name.name : building.name.plural;
}

const makeOwnedBuilding = (group: BuildingGroup): OwnedBuilding => {
    return {
        id: group,
        type: buildingDefaultTypeForGroup(group),
        amount: group === 'farm' ? 1 : 0,
        unlocked: UnlockedIf(group === 'farm'),
    };
}

export const initialOwnedBuildings = (): OwnedBuildings => {
    const object = buildingGroups.reduce((obj, item) => {
        return {
            ...obj,
            [item]: makeOwnedBuilding(item),
        };
    }, {});
    return object as OwnedBuildings;
}

export const buildingDefaultTypeForGroup = (group: BuildingGroup): BuildingType => {
    return buildingGroupToTypeBindings[group];
}

export const buildingGroupForType = (type: BuildingType): BuildingGroup => {
    return buildingsGroupBindings[type];
}

export const costForBuyingBuilding = (ownedBuilding: OwnedBuilding): ResourceAmount[] => {
    const building = getBuildingData(ownedBuilding.type);
    return building.cost.map((cost) => {
        return {
            id: cost.resource,
            amount: cost.baseAmount * Math.pow(cost.growth, ownedBuilding.amount),
        }
    });
}

export const canBuildMoreBuildings = (ownedBuilding: OwnedBuilding): boolean => {
    const building = getBuildingData(ownedBuilding.type);
    return building.maxAmount ? ownedBuilding.amount < building.maxAmount : true;
}

export const canAffordBuilding = (ownedBuilding: OwnedBuilding, ownedResources: OwnedResources): boolean => {
    if (!canBuildMoreBuildings(ownedBuilding)) {
        return false;
    }
    const cost = costForBuyingBuilding(ownedBuilding);

    for (let resource of cost) {
        if (ownedResources[resource.id].amount < resource.amount) {
            return false;
        }
    }
    return true;
};

export const canInteractWithBuilding = (ownedBuilding: OwnedBuilding): boolean => {
    const building = getBuildingData(ownedBuilding.type);
    return !!building.interaction && ownedBuilding.amount > 0;
};

export const canHeroInteractWithBuilding = (ownedBuilding: OwnedBuilding): boolean => {
    const building = getBuildingData(ownedBuilding.type);
    return !!building.heroInteraction && ownedBuilding.amount > 0;
};

export const buildingPerformAction = (state: Draft<GameState>, building: Draft<OwnedBuilding>, multiplier: number) => {
    const info = getBuildingData(building.type);
    if (info.generator) {
        buildingGenerate(state, building.id, info.generator, multiplier, multiplier)
    }
    if (info.action) {
        info.action(state, building, multiplier);
    }
};

export const willInteractionSucceed = (state: GameState, ownedBuilding: OwnedBuilding): boolean => {
    const info = getBuildingData(ownedBuilding.type);
    if (!info.interaction) {
        return false;
    }

    const buildingProductionCache = state.cache.productionPerSecond.interactions;
    const allRps = buildingProductionCache.find(item => item.id === ownedBuilding.id)?.resources ?? [];
    for (let cost of allRps) {
        if (cost.amount < 0 && -cost.amount > state.resources[cost.id].amount) {
            return false;
        }
    }
    return true;
};

export function resolveBuildingGenerator(state: GameState, generator: AnyBuildingResourceGenerator): BuildingResourceGenerator {
    if (generator.type == 'static') {
        return generator.generator;
    } else {
        return generator.generator(state);
    }
}

// TODO: use cache
export function buildingGenerate(state: Draft<GameState>, building: BuildingGroup, anyGenerator: AnyBuildingResourceGenerator, consumedMultiplier: number, producedMultiplier: number): boolean {
    const resolvedMultipliers = getMultipliersForBuildingGenerator(state, building, consumedMultiplier, producedMultiplier);
    const generator = resolveBuildingGenerator(state, anyGenerator);

    // Abort if can't sustain the consumed resources
    for (let cost of generator.consumed) {
        if (getResource(state, cost.id).amount < (cost.amount * resolvedMultipliers.consumed)) {
            return false;
        }
    }

    // Process consumed and generated resources
    generator.consumed.forEach((resource) => {
        getResource(state, resource.id).amount -= (resource.amount * resolvedMultipliers.consumed);
    });
    generator.generated.forEach((resource) => {
        getResource(state, resource.id).amount += (resource.amount * resolvedMultipliers.produced);
    })
    return true;
};

export const buildingPerformInteraction = (state: Draft<GameState>, building: Draft<OwnedBuilding>, multiplier: number) => {
    const info = getBuildingData(building.type);

    if (!info.interaction) {
        return;
    }

    switch (info.interaction.type) {
        case "generator":
            if (isUnlocked(building) && building.amount > 0) {
                const generator: AnyBuildingResourceGenerator = {
                    type: 'static',
                    generator: info.interaction.generator
                };
                const resolvedMultipliers = getMultipliersForInteraction(state, multiplier);
                buildingGenerate(state, building.id, generator, resolvedMultipliers.consumed, resolvedMultipliers.produced);
            }
            break;
        case "building":
            if (isUnlocked(building) && building.amount > 0) {
                const buildingData = getBuildingData(building.type);
                if (buildingData.generator) {
                    const resolvedMultipliers = getMultipliersForInteraction(state, multiplier);
                    buildingGenerate(state, building.id, buildingData.generator, resolvedMultipliers.consumed, resolvedMultipliers.produced);
                }
            }
            break;
        case "custom":
            info.interaction.action(state, building, multiplier);
    }
};

export const buildingExpectedProductionAfterUpgrade = (state: GameState, upgradeData: Upgrade): ResourceAmount[] => {
    if (upgradeData.effect.type === 'buildingUpgrade') {
        const currentBuilding = getBuilding(state, upgradeData.effect.group);
        const nextBuilding = getBuildingData(upgradeData.effect.replacement);

        if (nextBuilding.generator) {
            const generator = resolveBuildingGenerator(state, nextBuilding.generator);
            const multipliers = getMultipliersForBuildingGenerator(state, upgradeData.effect.group, currentBuilding.amount, currentBuilding.amount);
            const expectedConsumed = generator.consumed.map(amount => {
                return {
                    id: amount.id,
                    amount: -(amount.amount * multipliers.consumed),
                }
            });
            const expectedProduced = generator.generated.map(amount => {
                return {
                    id: amount.id,
                    amount: amount.amount * multipliers.produced,
                }
            });

            return [...expectedConsumed, ...expectedProduced];
        }
    }
    return [];
}


export function interactionIncreaseResource(group: ResourceGroup): BuildingInteraction {
    return interactionGenerator({
        consumed: [],
        generated: [{
            id: group,
            amount: 1,
        }]
    });
};

export function interactionBuildingGenerate(): BuildingInteraction {
    return {
        type: 'building',
    };
};

export function interactionGenerator(generator: BuildingResourceGenerator): BuildingInteraction {
    return {
        type: 'generator',
        generator: generator,
    };
};

export function heroClick(): HeroInteraction {
    return {
        type: 'click',
    };
}

export function heroIncreaseResource(group: ResourceGroup): HeroInteraction {
    return {
        type: 'resources',
        resource: group,
        amount: 1
    };
};
