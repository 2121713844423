import { Draft } from "@reduxjs/toolkit";
import { Unlockable } from "../../utils/lock";
import { ResourceAmount, ResourceGroup } from "../resources/resourceTypes";
import { EntityName } from "../shared/shared";
import { GameState } from "../gamestate";
import { TemplateStringMaker } from "../shared/templateString";

export const buildingGroups = [
    'farm',
    'cows',
    'grindstone',
    'churn',
    'oven',
    'well',
    'bakery',
    'coop',
    'jar',
    'coal',
    'study',
    'boiler',
    'cacaotree',
    'sugarcane',
    'mixer',
] as const;

export const buildingTypes = [
    'wheat',
    'farm',
    'irrigatedFarm',

    'milk',
    'cows',
    'fedCows',

    'grindstone',
    'windmill',

    'churn',
    'churnBarrel',

    'oven',
    'coalOven',

    'well',

    'firstBakery',
    'firstBakery-interactable',

    'coop',

    'jar',
    'jars',

    'coalMine',

    'study',

    'boiler',

    'cacaotree',
    'cacaoFarm',

    'sugarcane',
    'sugarFarm',

    'mixer',
] as const;

export type BuildingGroup = typeof buildingGroups[number];
export type BuildingType = typeof buildingTypes[number];

export type BuildingCost = {
    resource: ResourceGroup,
    baseAmount: number,
    growth: number,
}

export type BuildingResourceGenerator = {
    consumed: ResourceAmount[];
    generated: ResourceAmount[];
}
export type BuildingResourceDynamicGenerator = (state: GameState) => BuildingResourceGenerator;
export type AnyBuildingResourceGenerator =
    | { type: 'static', generator: BuildingResourceGenerator }
    | { type: 'dynamic', generator: BuildingResourceDynamicGenerator };

export type BuildingTreeBranch = {
    [K in BuildingType]?: Building
}
export type BuildingTree = {
    [K in BuildingGroup]: BuildingTreeBranch
}
export type BuildingsFlat = {
    [K in BuildingType]: Building
}

export type BuildingInteraction = BuildingInteractionGenerator | BuildingInteractionTickBuilding | BuildingInteractionCustom;
export type BuildingInteractionGenerator = {
    type: 'generator';
    generator: BuildingResourceGenerator;
}
export type BuildingInteractionTickBuilding = {
    type: 'building';
}
export type BuildingInteractionCustom = {
    type: 'custom';
    action: BuildingMutatingAction;
    tooltipDescription: string;
}

export type BuildingMutatingAction = (state: Draft<GameState>, self: Draft<OwnedBuilding>, tickMultiplier: number) => void;

export type HeroInteraction = HeroInteractionClick | HeroInteractionGenerateResource | HeroInteractionCustom;
export type HeroInteractionClick = {
    type: 'click';
};
export type HeroInteractionGenerateResource = {
    type: 'resources';
    resource: ResourceGroup;
    amount: number;
};
export type HeroInteractionCustom = {
    type: 'custom';
    action: BuildingMutatingAction;
    tooltipDescription: string;
};

export type Building = {
    name: EntityName;
    description: string;
    cost: BuildingCost[];
    maxAmount?: number;
    generator?: AnyBuildingResourceGenerator;
    dynamicDescription?: TemplateStringMaker;
    interaction?: BuildingInteraction;
    heroInteraction?: HeroInteraction;
    action?: BuildingMutatingAction;
}

export type OwnedBuilding = {
    id: BuildingGroup;
    type: BuildingType;
    amount: number;
    unlocked: Unlockable;
}

export type OwnedBuildings = {
    [K in BuildingGroup]: OwnedBuilding;
};

export type BuildingGroupToTypeBinding = {
    [K in BuildingGroup]: BuildingType
};

export type BuildingTypeToGroupBinding = {
    [K in BuildingType]: BuildingGroup
};

/////////////////////////////
/////////////////////////////
// Internals
/////////////////////////////
/////////////////////////////


export function makeFullGenerator(consumed: ResourceAmount[], generated: ResourceAmount[]): AnyBuildingResourceGenerator {
    return {
        type: 'static',
        generator: {
            consumed: consumed,
            generated: generated,
        },
    };
};

export function makeTransformer(consumed: ResourceAmount, generated: ResourceAmount): AnyBuildingResourceGenerator {
    return makeFullGenerator([consumed], [generated]);
};

export function makeSimpleGenerator(id: ResourceGroup, amount: number): AnyBuildingResourceGenerator {
    return makeFullGenerator([], [{
        id: id,
        amount: amount
    }]);
};

export function makeDynamicGenerator(generator: (state: GameState) => BuildingResourceGenerator): AnyBuildingResourceGenerator {
    return {
        type: 'dynamic',
        generator: generator,
    };
};

export function makeStaticGenerator(consumed: ResourceAmount[], generated: ResourceAmount[]): BuildingResourceGenerator {
    return {
        consumed: consumed,
        generated: generated,
    };
};

const REGULAR_COST_GROWTH = 1.15;

export const cannotPurchase = (): BuildingCost[] => [];
export const regularCost = (id: ResourceGroup, amount: number): BuildingCost => {
    return {
        resource: id,
        baseAmount: amount,
        growth: REGULAR_COST_GROWTH,
    };
};

export const consumed = (id: ResourceGroup, amount: number): ResourceAmount => {
    return {
        id: id,
        amount: amount
    };
};
export const generated = consumed;