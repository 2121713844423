import { useInteractionFeedbackContext } from './InteractionFeedbackContext';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { ReactNode, useRef, useState } from 'react';
import { shouldProvideVisualFeedbackForHeroAction } from '../../../core/hero';
import { willInteractionSucceed } from '../../../core/buildings/buildingUtils';

export type HeroInteractionFeedbackProps = {
    enabled: boolean;
    fullsize: boolean;
    children: ReactNode;
}

export const HeroInteractionFeedbackComponent = ({ enabled, fullsize, children }: HeroInteractionFeedbackProps) => {
    const state = useAppSelector((state: RootState) => state);
    const hero = useAppSelector((state: RootState) => state.hero);
    const buildings = useAppSelector((state: RootState) => state.buildings);
    const tickid = useAppSelector((state: RootState) => state.tickid);
    const queueInteractionAction = useInteractionFeedbackContext().queueInteraction;
    const heroRef = useRef<HTMLDivElement>(null);
    // Required to avoid React spamming refreshes
    const [lastRenderedTick, setLastRenderedTick] = useState('');

    if (enabled) {
        if (lastRenderedTick !== tickid) {
            if (hero && hero.target && heroRef.current && shouldProvideVisualFeedbackForHeroAction(hero, buildings)) {
                const building = buildings[hero.target];
                const rect = heroRef.current.getBoundingClientRect();
                // This isn't really accurate because the interaction might have already happened
                const success = willInteractionSucceed(state, building);
                queueInteractionAction(building, rect.left + rect.width + 4, rect.top + rect.height, false, success);
            }
            setLastRenderedTick(tickid);
        }

        return (
            <div
                ref={heroRef}
                className={`HeroInteractionFeedbackAnchor${fullsize ? ' fullsize' : ''}`}>
                {children}
            </div>
        );
    } else {
        return <>{children}</>;
    }
};
