import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { BuildingGroup } from '../../core/buildings/buildingTypes';
import { getBuildingData } from '../../core/buildings/buildingUtils';
import { ResourceProductionTableComponent } from '../shared/ResourceProductionTable';

type GameTooltipBuildingProps = {
    buildingGroup: BuildingGroup;
};

export const GameTooltipBuilding = ({ buildingGroup }: GameTooltipBuildingProps) => {
    const building = useAppSelector((state: RootState) => state.buildings[buildingGroup]);
    const singleBuildingProductionCache = useAppSelector((state: RootState) => state.cache.productionPerSecond.buildingsForSingleAmount);

    const buildingData = getBuildingData(building.type);

    if (buildingData.generator) {
        const allRps = singleBuildingProductionCache.find(item => item.id === building.id)?.resources ?? [];
        return (
            <>
                Each new building will generate:
                <ResourceProductionTableComponent showHeaders={false} showPerSeconds resourceAmounts={allRps} />
            </>
        );
    }
    return <></>;
}
