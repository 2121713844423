import { useAppDispatch } from '../app/hooks';
import { tick } from '../core/game';
import { tickInterval } from '../core/gamestate';
import useInterval from './shared/useInterval';

export function Game() {
    const dispatch = useAppDispatch();

    useInterval(() => dispatch(tick()), tickInterval);
    return (<></>);
}
