import './Buildings.css'
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { BuildingGroup } from '../../core/buildings/buildingTypes';
import { buildingDisplayName } from '../../core/buildings/buildingUtils';

export type BuildingIconSize = 'medium' | 'small';
export type BuildingIconProps = {
    building: BuildingGroup;
    size: BuildingIconSize;
    includeName: boolean;
};

export const BuildingIcon = ({ building, size, includeName }: BuildingIconProps) => {
    const buildings = useAppSelector((state: RootState) => state.buildings);
    const buildingName = buildingDisplayName(buildings[building]);

    return (
        <span className='BuildingIconContainer'>
            <img
                className={`BuildingIcon ${size}`}
                alt={buildingName}
                title={buildingName}
                src={`./icons/buildings/${building}.png`}>
            </img>
            {includeName && <span className='BuildingIconName'>{buildingName}</span>}
        </span>
    );
};
