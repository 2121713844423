import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { buyUpgrade } from '../../core/game';
import { AvailableUpgrade, UpgradeTag } from '../../core/upgrades/upgradeTypes';
import { canAffordUpgrade, getUpgradeData } from '../../core/upgrades/upgradeUtils';
import { gameTooltipId, upgradeTooltipTarget } from '../tooltips/tooltipData';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { ResourceCostButtonPart } from '../shared/ResourceCostButtonPart';


export type UpgradeComponentProps = {
    upgrade: AvailableUpgrade;
};

export const UpgradeComponent = ({ upgrade }: UpgradeComponentProps) => {
    const resources = useAppSelector((state: RootState) => state.resources);
    const dispatch = useAppDispatch();

    const data = getUpgradeData(upgrade.id);
    const onClick = () => dispatch(buyUpgrade(upgrade.id));
    const canAfford = canAffordUpgrade(upgrade, resources);

    const variantForTag = (tag: UpgradeTag): ButtonVariant => {
        switch (tag) {
            case 'regular':
                return 'secondary';
            case 'important':
                return 'success';
            case 'ability':
                return 'info';
        }
    };

    return (
        <span
            data-tooltip-id={gameTooltipId}
            data-tooltip-content={upgradeTooltipTarget(upgrade.id, !canAfford)}
            data-tooltip-place='left'>
            <Button
                disabled={!canAfford}
                onClick={onClick}
                variant={variantForTag(data.tag)}
            >
                <ResourceCostButtonPart name={data.name} totalCost={data.cost} />
            </Button>
        </span>

    );
};
