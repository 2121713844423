import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Draft } from "@reduxjs/toolkit";

import { BuildingGroup, buildingGroups, OwnedBuilding } from './buildings/buildingTypes';
import { upgrades, UpgradeType } from './upgrades/upgradeTypes';
import { processUnlocks } from './unlocks/unlocks';

import { initialOwnedResources } from './resources/resourceUtils';
import { buildingPerformAction, buildingPerformInteraction, canAffordBuilding, costForBuyingBuilding, initialOwnedBuildings } from './buildings/buildingUtils';
import { applyUpgradeEffect, canAffordUpgrade, getUpgradeData } from './upgrades/upgradeUtils';

import { GameState, getBuilding, getResource, getSpell, getUpgrade, ticksPerSeconds } from './gamestate';
import { isUnlocked, setUnlocked, Unlockable } from '../utils/lock';
import { emptyCache } from './cache/cache';
import { rebuildCache } from './cache/cacheUtils';
import { resourceGroups } from './resources/resourceTypes';
import { canCastSpell, getSpellData, initialAvailableSpells, performCastSpell, spellTickUpdate } from './spells/spellUtils';
import { heroTickUpdate, newHero } from './hero';
import { v4 as uuidv4 } from 'uuid';
import { SpellType, spellTypes } from './spells/spellTypes';
import { hasTrait } from './buffs/buffUtils';
import { unlockSpell } from './unlocks/unlockUtils';

export const initialState: GameState = {
    resources: initialOwnedResources(),
    buildings: initialOwnedBuildings(),
    upgrades: [],
    spells: initialAvailableSpells(),

    buffs: [],

    cache: emptyCache(),
    tickid: '',
};

const buildingTick = (state: Draft<GameState>, building: Draft<OwnedBuilding>) => {
    buildingPerformAction(state, building, building.amount / ticksPerSeconds);
};

export const GameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        ////////////
        // Debug
        debugDoubleResources: (state) => {
            // Double all resources
            for (let resourceGroup of resourceGroups) {
                const resource = state.resources[resourceGroup];
                if (isUnlocked(resource)) {
                    resource.amount *= 2;
                }
            }
        },
        debugUnlockAllAndGainResources: (state) => {
            // Unlocks everything and get +1k of all resources
            for (let resourceGroup of resourceGroups) {
                const resource = state.resources[resourceGroup];
                setUnlocked(resource);
                resource.amount += 1000;
            }

            for (let buildingGroup of buildingGroups) {
                const building = state.buildings[buildingGroup];
                setUnlocked(building);
                if (building.id === 'bakery') {
                    building.amount = 1;
                } else {
                    building.amount += 10;
                }
            }

            for (let upgradeType of upgrades) {
                const upgrade = getUpgrade(state, upgradeType);
                if (!upgrade.owned) {
                    upgrade.unlocked = Unlockable.Unlocked;
                    upgrade.owned = true;
                    applyUpgradeEffect(state, upgradeType);
                }
            }
        },
        debugGrantHero: (state) => {
            if (!state.hero) {
                state.hero = newHero();
            }
        },
        debugGrantAllSpells: (state) => {
            for (let spell of spellTypes) {
                unlockSpell(state, spell);
            }
        },
        debugRefreshCooldowns: (state) => {
            if (state.hero) {
                state.hero.cooldown = 0;
                state.hero.recovery = 0;
            }
            for (let spellType of spellTypes) {
                const spell = state.spells[spellType];
                spell.cooldown = 0;
                if (spell.status.active) {
                    const data = getSpellData(spell.id);
                    spell.status = { active: false };
                    if (data.effect.type === 'temporary') {
                        data.effect.deactivate(state);
                    }
                }
            }
        },
        // end Debug
        ////////////

        tick: (state) => {
            state.cache = rebuildCache(state);
            // Buildings
            buildingGroups.forEach(buildingGroup => {
                const building = getBuilding(state, buildingGroup);
                if (isUnlocked(building) && building.amount > 0) {
                    buildingTick(state, building);
                }
            });
            // Hero
            if (state.hero) {
                heroTickUpdate(state);
            }
            // Spells
            spellTypes.forEach(spellType => {
                const spell = getSpell(state, spellType);
                if (isUnlocked(spell)) {
                    spellTickUpdate(state, spellType);
                }
            });
            processUnlocks(state);
            state.tickid = uuidv4();
        },
        onBuildingUserInteraction: (state, action: PayloadAction<BuildingGroup>) => {
            const building = getBuilding(state, action.payload);
            if (isUnlocked(building) && !hasTrait(state, 'manual-interaction-disabled')) {
                buildingPerformInteraction(state, building, 1);
            }
        },
        onMoveHero: (state, action: PayloadAction<BuildingGroup>) => {
            if (state.hero) {
                if (state.hero.target === action.payload) {
                    state.hero.target = undefined;
                } else {
                    state.hero.target = action.payload;
                }
            }
        },
        buyBuilding: (state, action: PayloadAction<BuildingGroup>) => {
            const building = getBuilding(state, action.payload);

            if (canAffordBuilding(building, state.resources)) {
                const cost = costForBuyingBuilding(building);
                cost.forEach((resource) => {
                    getResource(state, resource.id).amount -= resource.amount;
                });
                building.amount++;
            }
        },
        buyUpgrade: (state, action: PayloadAction<UpgradeType>) => {
            const upgradeType = action.payload;
            const upgrade = getUpgrade(state, upgradeType);
            if (canAffordUpgrade(upgrade, state.resources)) {
                const upgradeData = getUpgradeData(upgrade.id);
                upgradeData.cost.forEach((cost) => {
                    getResource(state, cost.id).amount -= cost.amount;
                });
                upgrade.owned = true;
                applyUpgradeEffect(state, upgradeType);
            }
        },
        castSpell: (state, action: PayloadAction<SpellType>) => {
            const spellType = action.payload;

            if (canCastSpell(state, spellType)) {
                performCastSpell(state, spellType);
            }
        },
        hardReset: () => {
            return initialState;
        },
    },
});

export const {
    debugDoubleResources,
    debugUnlockAllAndGainResources,
    debugGrantHero,
    debugRefreshCooldowns,
    debugGrantAllSpells,

    tick,
    onBuildingUserInteraction,
    onMoveHero,
    buyBuilding,
    buyUpgrade,
    castSpell,
    hardReset
} = GameSlice.actions;

export default GameSlice.reducer;
