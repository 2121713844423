import { Draft } from "@reduxjs/toolkit";
import { addBuff, addBuffs, removeBuff, removeBuffs } from "../buffs/buffUtils";
import { GameState, getBuilding, getResource } from "../gamestate";
import { ticksPerSeconds } from "../gamestate"
import { Spell, SpellType, Tier1SpellType, tier1Spells } from "./spellTypes"
import { makeBuildingTraitBuff, makeInstantSpellEffect, makeMultiplierBuff, makeSpecialBuff, makeStaticSpellMultiplier, makeTemporaryBuffSpellEffect } from "./spellUtils";
import { multiplierString, plainString, resourceAmountString, makeTemplateString } from "../shared/templateString";
import { buildingGroups } from "../buildings/buildingTypes";
import { isUnlocked } from "../../utils/lock";
import { getBuildingData } from "../buildings/buildingUtils";
import _ from "lodash";

type Tier1SpellData = {
    [K in Tier1SpellType]: Spell
}

type SpellData = {
    [K in SpellType]: Spell
}

const seconds = (n: number): number => n * ticksPerSeconds;
const minutes = (n: number): number => seconds(n * 60);


export const tier1SpellData: Tier1SpellData = {
    hardwork: {
        name: 'Hard work',
        getDescription: (_state: GameState) => {
            return makeTemplateString([
                plainString('You do an honest job and earn '),
                resourceAmountString('money', 100),
            ]);
        },
        effect: makeInstantSpellEffect((state: Draft<GameState>): void => {
            getResource(state, 'money').amount += 100;
        }),
        cooldown: minutes(2),
        recovery: minutes(1),
    },

    overtime: {
        name: 'Overtime',
        getDescription: (_state: GameState) => {
            return makeTemplateString([
                plainString('Hero interactions produce '),
                multiplierString(3),
                plainString(' more, but you can\'t click manually'),
            ]);
        },
        effect: makeTemporaryBuffSpellEffect({
            activate: (state: Draft<GameState>): void => {
                addBuff(state, makeMultiplierBuff('spell-overtime-multiplier', [
                    makeStaticSpellMultiplier('interactions', 3),
                ]));
                addBuff(state, makeSpecialBuff('spell-overtime-disable-clicks', ['manual-interaction-disabled']));
            },
            deactivate: (state: Draft<GameState>): void => {
                removeBuffs(state, ['spell-overtime-multiplier', 'spell-overtime-disable-clicks']);
            },
            duration: minutes(1),
        }),
        cooldown: minutes(2),
        recovery: 0,
    },

    sales: {
        name: 'Sales',
        getDescription: (_state: GameState) => {
            return makeTemplateString([
                plainString('Your bakery sells '),
                multiplierString(5),
                plainString(' faster, but for half the price'),
            ]);
        },
        effect: makeTemporaryBuffSpellEffect({
            activate: (state: Draft<GameState>): void => {
                addBuff(state, makeMultiplierBuff('spell-sales', [
                    makeStaticSpellMultiplier('bakery/produced', 2.5),
                    makeStaticSpellMultiplier('bakery/consumed', 5),
                ]));
            },
            deactivate: (state: Draft<GameState>): void => {
                removeBuff(state, 'spell-sales');
            },
            duration: minutes(1),
        }),
        cooldown: minutes(5),
        recovery: seconds(5),
    },

    strike: {
        name: 'Strike',
        getDescription: (_state: GameState) => {
            return makeTemplateString([
                plainString('A random building stops producing, but all others produce '),
                multiplierString(3),
                plainString(' more'),
            ]);
        },
        effect: makeTemporaryBuffSpellEffect({
            activate: (state: Draft<GameState>): void => {
                const eligibleBuildings = buildingGroups
                    .map(bg => getBuilding(state, bg))
                    .filter(building => {
                        return isUnlocked(building) && building.amount > 0 && !!getBuildingData(building.type).generator;
                    });

                const strikedBuilding = _.sample(eligibleBuildings);
                if (strikedBuilding) {
                    addBuffs(state, [
                        makeBuildingTraitBuff('spell-strike-building-trait', strikedBuilding.id, ['strike']),
                        makeMultiplierBuff('spell-strike-multiplier', [
                            makeStaticSpellMultiplier('buildings', 3),
                            makeStaticSpellMultiplier(strikedBuilding.id, 0),
                        ]),
                    ]);
                }
            },
            deactivate: (state: Draft<GameState>): void => {
                removeBuffs(state, ['spell-strike-multiplier', 'spell-strike-building-trait']);
            },
            duration: minutes(1),
        }),
        cooldown: minutes(10),
        recovery: minutes(2),
    },


};

export const allSpellData: SpellData = { ...tier1SpellData };
