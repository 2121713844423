import { BuildingGroup } from '../buildings/buildingTypes';
import { GameState } from "../gamestate";
import { getUpgradeData } from '../upgrades/upgradeUtils';
import { MultiplierType, ResolvedGeneratorMultiplier } from './multiplierTypes';

// TODO: if it becomes a bottleneck, instead pre-compute everything and store it in the state as cache
export const getResolvedMultiplier = (state: GameState, target: MultiplierType): number => {
    const upgradeMultipliers = state.upgrades.flatMap(upgrade => {
        if (!upgrade.owned) {
            return [];
        }
        const effect = getUpgradeData(upgrade.id).effect;
        return effect.type === 'multipliers' ? effect.multipliers : [];
    });

    const buffMultipliers = state.buffs.flatMap(buff => {
        return buff.type === 'multipliers' ? buff.multipliers : [];
    });

    const multipliers = [...upgradeMultipliers, ...buffMultipliers];
    return multipliers.reduce((acc, current) => {
        const amount = current.dynamic ? current.resolve(state) : current.amount;
        return current.type === target ? acc * amount : acc;
    }, 1);
};

export function getMultipliersForInteraction(state: GameState, extraMultiplier: number): ResolvedGeneratorMultiplier {
    const interactionMultiplier = extraMultiplier * getResolvedMultiplier(state, 'interactions');
    const resolvedConsumedMultiplier = interactionMultiplier * getResolvedMultiplier(state, 'interactions/consumed');
    const resolvedProducedMultiplier = interactionMultiplier * getResolvedMultiplier(state, 'interactions/produced');
    return {
        consumed: resolvedConsumedMultiplier,
        produced: resolvedProducedMultiplier,
    };
};

export function getMultipliersForBuildingGenerator(state: GameState, building: BuildingGroup, extraConsumedMultiplier: number, extraProducedMultiplier: number): ResolvedGeneratorMultiplier {
    const globalAllBuildingsMultiplier = getResolvedMultiplier(state, 'buildings');
    const globalBuildingMultiplier = getResolvedMultiplier(state, building);

    const resolvedConsumedMultiplier = extraConsumedMultiplier *
        globalAllBuildingsMultiplier *
        globalBuildingMultiplier *
        getResolvedMultiplier(state, `${building}/consumed` as MultiplierType) *
        getResolvedMultiplier(state, 'buildings/consumed');

    const resolvedProducedMultiplier = extraProducedMultiplier *
        globalAllBuildingsMultiplier *
        globalBuildingMultiplier *
        getResolvedMultiplier(state, `${building}/produced` as MultiplierType) *
        getResolvedMultiplier(state, 'buildings/produced');

    return {
        consumed: resolvedConsumedMultiplier,
        produced: resolvedProducedMultiplier,
    };
};

export function getCombinedMultipliersForInteractionOnBuildingGenerator(state: GameState, building: BuildingGroup, extraMultiplier: number): ResolvedGeneratorMultiplier {
    const interactionMultipliers = getMultipliersForInteraction(state, extraMultiplier);
    return getMultipliersForBuildingGenerator(state, building, interactionMultipliers.consumed, interactionMultipliers.produced);
};
