// import * as ADNotations from "@antimatter-dimensions/notations";
const ADNotations: any = require("@antimatter-dimensions/notations");

export class NumberFormatter {
    private formatter: any

    private static _instance: NumberFormatter;
    private constructor() {
        this.formatter = new ADNotations.MixedScientificNotation();
    }

    private static get Instance() {
        return this._instance || (this._instance = new this());
    }

    // General number formatting for amounts of stuff
    public static format(n: number): string {
        const baseFormatted: string = NumberFormatter.Instance.formatter.format(n, 2, 2);
        return baseFormatted.replace(/\s/g, "");
    }

    // Formatter for production numbers. Will add a + to positive numbers
    public static formatRelative(n: number): string {
        return (n >= 0 ? '+' : '') + this.format(n);
    }

    // Formatter for numbers not expected to change, removes unnecessary decimals
    public static formatStatic(n: number): string {
        const baseFormatted = this.format(n);
        return baseFormatted.replace(/\.00/g, "");
    }
}
