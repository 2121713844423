import './ResourceComponent.css'
import { ResourceGroup } from '../../core/resources/resourceTypes';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { getResourceData } from '../../core/resources/resourceUtils';

export type ResourceIconSize = 'medium' | 'small';
export type ResourceIconProps = {
    resource: ResourceGroup;
    size: ResourceIconSize;
    includeName: boolean;
};

export const ResourceIcon = ({ resource, size, includeName }: ResourceIconProps) => {
    const resources = useAppSelector((state: RootState) => state.resources);
    const resourceData = getResourceData(resources[resource].type);

    return (
        <span className='ResourceIconContainer'>
            <img
                className={`ResourceIcon ${size}`}
                alt={resourceData.fullName}
                title={resourceData.fullName}
                src={`./icons/resources/${resource}.png`}>
            </img>
            {includeName && <span className='ResourceIconName'>{resourceData.shortName}</span>}
        </span>
    );
};
