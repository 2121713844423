import './GameTooltip.css'

import { Tooltip } from 'react-tooltip';
import { BuildingGroup } from '../../core/buildings/buildingTypes';
import { UpgradeType } from '../../core/upgrades/upgradeTypes';
import { GameTooltipBuilding } from './GameTooltipBuilding';
import { GameTooltipUpgrade } from './GameTooltipUpgrade';
import { gameTooltipId, GameTooltipTarget } from './tooltipData';
import { GameTooltipBuildingInteraction } from './GameTooltipBuildingInteraction';
import { GameTooltipBuildingHero } from './GameTooltipBuildingHero';
import { GameTooltipSpell } from './GameTooltipSpell';
import { SpellType } from '../../core/spells/spellTypes';
import { GameTooltipHero } from './GameTooltipHero';

function tooltipSelector(target: GameTooltipTarget): React.ReactNode {
    const parts = target.split('/');
    if (parts.length >= 2) {
        switch (parts[0]) {
            case 'building-buy':
                return <GameTooltipBuilding buildingGroup={parts[1] as BuildingGroup} />;
            case 'building-interact':
                return <GameTooltipBuildingInteraction buildingGroup={parts[1] as BuildingGroup} />;
            case 'building-hero':
                return <GameTooltipBuildingHero buildingGroup={parts[1] as BuildingGroup} />;
            case 'upgrade':
                return <GameTooltipUpgrade upgradeType={parts[1] as UpgradeType} />;
            case 'spell':
                return <GameTooltipSpell spellType={parts[1] as SpellType} />;
            case 'static':
                {
                    switch (parts[1]) {
                        case 'hero':
                            return <GameTooltipHero />;
                        default:
                            break;
                    }
                }
                break;
            default:
                break;
        }
    }

    return <span>{target}</span>;
};

function dynamicContent(tip: string): React.ReactNode {
    if (tip) {
        const isTargetDisabled = tip.split('/').at(-1) === 'disabled';
        return (
            <div className='GameTooltip'>
                <div className={isTargetDisabled ? 'disabled' : 'enabled'}>
                    {tooltipSelector(tip as GameTooltipTarget)}
                </div>
            </div>
        );
    }
}

export function GameTooltip() {
    return (
        <Tooltip
            id={gameTooltipId}
            className='TooltipOverride'
            opacity={1}
            border="1px solid #596071"
            render={({ content, activeAnchor }) => (
                dynamicContent(content ?? "")
            )}
        />
    );
}
