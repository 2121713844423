import './Buildings.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { OwnedBuilding } from '../../core/buildings/buildingTypes';
import { canAffordBuilding, canBuildMoreBuildings, costForBuyingBuilding, getBuildingData } from '../../core/buildings/buildingUtils';
import { buyBuilding, buyUpgrade } from '../../core/game';
import { AvailableUpgrade } from '../../core/upgrades/upgradeTypes';
import { canAffordUpgrade, getUpgradeData } from '../../core/upgrades/upgradeUtils';

import Button from 'react-bootstrap/Button';
import { buildingBuyTooltipTarget, gameTooltipId, upgradeTooltipTarget } from '../tooltips/tooltipData';
import { isUnlockedAndVisible } from '../../utils/lock';
import { ResourceCostButtonPart } from '../shared/ResourceCostButtonPart';

export type BuildingBuyUpgradeButtonsProps = {
    building: OwnedBuilding;
};

export const BuildingBuyUpgradeButtonsComponent = ({ building }: BuildingBuyUpgradeButtonsProps) => {
    const resources = useAppSelector((state: RootState) => state.resources);
    const upgrades = useAppSelector((state: RootState) => state.upgrades);
    const dispatch = useAppDispatch();

    const canBuy = canBuildMoreBuildings(building);
    const buildingData = getBuildingData(building.type);
    const isUniqueBuilding = buildingData.maxAmount === 1;

    const buildingUpgrade = upgrades.find(upgrade => {
        const effect = getUpgradeData(upgrade.id).effect;
        return effect.type === 'buildingUpgrade' && effect.target === building.type && isUnlockedAndVisible(upgrade);
    });
    const hasUpgrade = !!buildingUpgrade;


    const renderBuildingUpgrade = (buildingUpgrade: AvailableUpgrade): JSX.Element => {
        const buildingUpgradeData = getUpgradeData(buildingUpgrade.id);
        const onClickUpgrade = () => dispatch(buyUpgrade(buildingUpgrade.id));
        const canAffordUp = canAffordUpgrade(buildingUpgrade, resources);
        return (
            <span
                data-tooltip-id={gameTooltipId}
                data-tooltip-content={upgradeTooltipTarget(buildingUpgrade.id, !canAffordUp)}
                data-tooltip-place='right-end'>
                <Button
                    variant="success"
                    disabled={!canAffordUp}
                    onClick={onClickUpgrade}
                    style={{ width: "100%" }}>
                    <ResourceCostButtonPart name={buildingUpgradeData.name} totalCost={buildingUpgradeData.cost} />
                </Button>
            </span>
        );
    };

    const renderBuyButton = (): JSX.Element => {
        if (canBuy) {
            const onClickBuy = () => dispatch(buyBuilding(building.id));
            const cost = costForBuyingBuilding(building);
            const canAfford = canAffordBuilding(building, resources);

            return (
                <span
                    data-tooltip-id={gameTooltipId}
                    data-tooltip-content={buildingBuyTooltipTarget(building.id, !canAfford)}
                    data-tooltip-place='right-end'>
                    <Button
                        variant='primary'
                        disabled={!canAfford}
                        onClick={onClickBuy}
                        style={{ width: "100%" }}>
                        <ResourceCostButtonPart name='Buy' totalCost={cost} />
                    </Button>
                </span>
            );

        } else {
            return <Button variant='primary' disabled>max amount reached</Button>
        }
    }

    return (
        <>
            {(!isUniqueBuilding && (canBuy || !hasUpgrade)) && renderBuyButton()}
            {hasUpgrade && renderBuildingUpgrade(buildingUpgrade!)}
        </>
    );
};
