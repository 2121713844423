import './Hero.css';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

export type HeroImageProps = {
    size: 'small' | 'full';
    showTitle: boolean
};

export const HeroImage = ({ size, showTitle }: HeroImageProps) => {
    const hero = useAppSelector((state: RootState) => state.hero);

    const getHeroImage = (): string => {
        if (!hero) {
            return '';
        }

        if (hero.recovery > 0) {
            return `./icons/hero/hero-recover.png`;
        } else if (hero.target) {
            return `./icons/hero/hero.png`;
        } else {
            return `./icons/hero/hero-idle.png`;
        }
    };

    return (
        <img
            className={`HeroImage ${size}`}
            alt='Hero'
            title={showTitle ? 'Hero' : undefined}
            src={getHeroImage()}>
        </img>
    );
}
