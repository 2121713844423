import { Resource, ResourceType } from "./resourceTypes";

type ResourceData = {
    [K in ResourceType]: Resource
}

export const allResources: ResourceData = {
    wheat: {
        fullName: 'Wheat',
        shortName: 'wheat',
        tier: 1,
    },

    milk: {
        fullName: 'Milk',
        shortName: 'milk',
        tier: 1,
    },

    flour: {
        fullName: 'Flour',
        shortName: 'flour',
        tier: 1,
    },

    butter: {
        fullName: 'Butter',
        shortName: 'butter',
        tier: 1,
    },

    pastry: {
        fullName: 'Pastry',
        shortName: 'pastry',
        tier: 1,
    },
    goldenpastry: {
        fullName: 'Pastry',
        shortName: 'pastry',
        tier: 2,
    },
    protocroissant: {
        fullName: 'Protocroissant',
        shortName: 'pcroissant',
        tier: 3,
    },
    croissant: {
        fullName: 'Croissant',
        shortName: 'croissant',
        tier: 4,
    },

    water: {
        fullName: 'Water',
        shortName: 'water',
        tier: 1,
    },

    money: {
        fullName: 'Money',
        shortName: '€',
        tier: 1,
    },

    egg: {
        fullName: 'Egg',
        shortName: 'egg',
        tier: 1,
    },

    coal: {
        fullName: 'Coal',
        shortName: 'coal',
        tier: 1,
    },

    knowledge: {
        fullName: 'Knowledge',
        shortName: 'knowledge',
        tier: 1,
    },

    yeast: {
        fullName: 'Yeast',
        shortName: 'yeast',
        tier: 1,
    },

    steam: {
        fullName: 'Steam',
        shortName: 'steam',
        tier: 1,
    },

    cacao: {
        fullName: 'Cacao',
        shortName: 'cacao',
        tier: 1,
    },

    sugar: {
        fullName: 'Sugar',
        shortName: 'sugar',
        tier: 1,
    },

    chocolate: {
        fullName: 'Chocolate',
        shortName: 'chocolate',
        tier: 1,
    },
};
