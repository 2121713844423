import { isUnlocked } from "../../utils/lock";
import { BuildingGroup, BuildingResourceGenerator, buildingGroups } from "../buildings/buildingTypes";
import { getBuildingData, resolveBuildingGenerator } from "../buildings/buildingUtils";
import { GameState } from "../gamestate"
import { getCombinedMultipliersForInteractionOnBuildingGenerator, getMultipliersForBuildingGenerator, getResolvedMultiplier } from "../multipliers/multiplierUtils";
import { ResourceAmount, ResourceGroup } from "../resources/resourceTypes";
import { EntityProductionCache, GameCache } from "./cache"

function buildBuildingsCache(state: GameState, ignoreAmount: boolean): EntityProductionCache<BuildingGroup>[] {
    const allOwnedBuilding = buildingGroups.map(g => state.buildings[g]);
    const ownedBuildings = allOwnedBuilding.filter(b => isUnlocked(b) && (ignoreAmount || (b.amount > 0)));
    return ownedBuildings.flatMap(building => {
        const data = getBuildingData(building.type);
        if (data.generator) {
            const generator = resolveBuildingGenerator(state, data.generator);
            const amountMultiplier = ignoreAmount ? 1 : building.amount;
            const resolvedMultipliers = getMultipliersForBuildingGenerator(state, building.id, amountMultiplier, amountMultiplier);
            const consumed = generator.consumed.map(res => {
                return {
                    id: res.id,
                    amount: -(res.amount * resolvedMultipliers.consumed),
                };
            });
            const produced = generator.generated.map(res => {
                return {
                    id: res.id,
                    amount: res.amount * resolvedMultipliers.produced,
                };
            });

            return [{
                id: building.id,
                resources: consumed.concat(produced),
            }];
        }
        return [];
    });
};

function buildInteractionCache(state: GameState): EntityProductionCache<BuildingGroup>[] {
    const allOwnedBuilding = buildingGroups.map(g => state.buildings[g]);
    const ownedBuildings = allOwnedBuilding.filter(b => isUnlocked(b) && b.amount > 0);
    return ownedBuildings.flatMap(building => {
        const data = getBuildingData(building.type);
        if (data.interaction) {
            var generator: BuildingResourceGenerator | null = null;

            switch (data.interaction.type) {
                case "generator":
                    generator = data.interaction.generator;
                    break;
                case "building":
                    if (data.generator) {
                        generator = resolveBuildingGenerator(state, data.generator);
                    }
                    break;
                case "custom":
                    break;
            }

            if (generator) {
                const resolvedMultipliers = getCombinedMultipliersForInteractionOnBuildingGenerator(state, building.id, 1);
                const consumed = generator.consumed.map(res => {
                    return {
                        id: res.id,
                        amount: -(res.amount * resolvedMultipliers.consumed),
                    };
                });
                const produced = generator.generated.map(res => {
                    return {
                        id: res.id,
                        amount: res.amount * resolvedMultipliers.produced,
                    };
                });

                return [{
                    id: building.id,
                    resources: consumed.concat(produced),
                }];
            }
        }
        return [];
    });
}

function buildHeroCache(state: GameState, buildingCache: EntityProductionCache<BuildingGroup>[]): ResourceAmount[] {
    if (state.hero?.target) {
        const target = state.hero.target;
        return buildingCache.find(item => item.id === target)?.resources ?? [];
    } else {
        return [];
    }
};

function buildOverallResourcesCache(state: GameState, buildingsCache: EntityProductionCache<BuildingGroup>[]): ResourceAmount[] {
    let allResources = new Map<ResourceGroup, number>();
    const pushResource = (res: ResourceAmount): void => {
        const prev = allResources.get(res.id) ?? 0;
        allResources.set(res.id, prev + res.amount);
    };

    buildingsCache.forEach(building => {
        building.resources.forEach(pushResource);
    });
    // heroCache.forEach(pushResource);

    return Array.from(allResources.entries(), ([type, amount]) => {
        return {
            id: type,
            amount: amount
        };
    });
};

export const rebuildCache = (state: GameState): GameCache => {
    const buildingsCache = buildBuildingsCache(state, false);
    const buildingsNoAmountCache = buildBuildingsCache(state, true);
    const interactionsCache = buildInteractionCache(state);
    const heroCache = buildHeroCache(state, buildingsCache);
    const resourcesCache = buildOverallResourcesCache(state, buildingsCache);

    return {
        resourcePerSecond: resourcesCache,
        productionPerSecond: {
            buildings: buildingsCache,
            buildingsForSingleAmount: buildingsNoAmountCache,
            interactions: interactionsCache,
            hero: heroCache,
        },
    };
};
