import { Draft } from "@reduxjs/toolkit";
import { BuildingGroup, OwnedBuildings } from "./buildings/buildingTypes";
import { GameState, getBuilding, getResource, ticksPerSeconds } from "./gamestate";
import { buildingPerformInteraction, getBuildingData } from "./buildings/buildingUtils";
import { isUnlocked } from "../utils/lock";
import { getResolvedMultiplier } from "./multipliers/multiplierUtils";

export type CurrentHero = {
    target?: BuildingGroup;
    cooldown: number; // Ticks before next interaction
    recovery: number; // Ticks before the hero can interact again
};

export const newHero = (): CurrentHero => {
    return {
        cooldown: 0,
        recovery: 0,
    }
};

export const canHeroPerformInteraction = (hero: CurrentHero, buildings: OwnedBuildings): boolean => {
    if (hero.target && hero.cooldown <= 0 && hero.recovery <= 0) {
        const building = buildings[hero.target];
        const info = getBuildingData(building.type);
        if (isUnlocked(building) && building.amount > 0 && info.heroInteraction) {
            return true;
        }
    }
    return false;
};

export const shouldProvideVisualFeedbackForHeroAction = (hero: CurrentHero, buildings: OwnedBuildings): boolean => {
    if (canHeroPerformInteraction(hero, buildings)) {
        const building = buildings[hero.target!];
        const info = getBuildingData(building.type);
        return !!info.heroInteraction && info.heroInteraction.type !== 'custom';
    }
    return false;
};

export const heroPerformInteraction = (state: Draft<GameState>) => {
    if (state.hero && state.hero.target) {
        const ownedBuilding = getBuilding(state, state.hero.target);
        const info = getBuildingData(ownedBuilding.type);

        if (info.heroInteraction) {
            switch (info.heroInteraction.type) {
                case "click":
                    buildingPerformInteraction(state, ownedBuilding, 1);
                    break;
                case "resources":
                    const resource = getResource(state, info.heroInteraction.resource);
                    if (isUnlocked(resource)) {
                        resource.amount += info.heroInteraction.amount * getResolvedMultiplier(state, 'interactions');
                    }
                    break;
                case "custom":
                    info.heroInteraction.action(state, ownedBuilding, 1);
                    break;
            }
        }
    }
};

export const heroTickUpdate = (state: Draft<GameState>) => {
    if (!state.hero) {
        return;
    }

    if (canHeroPerformInteraction(state.hero, state.buildings)) {
        heroPerformInteraction(state);
        state.hero.cooldown = ticksPerSeconds;
    } else {
        if (state.hero.cooldown > 0) {
            state.hero.cooldown--;
        }
        if (state.hero.recovery > 0) {
            state.hero.recovery--;
        }
    }
};
