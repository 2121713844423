import { createContext, useContext } from "react";
import { OwnedBuilding } from "../../../core/buildings/buildingTypes";

export type InteractionFeedbackElement = {
    target: OwnedBuilding;
    x: number;
    y: number;
    succeeded: boolean;
    uuid: string;
    timestamp: number;
};

export type InteractionFeedbackQueue = {
    interactions: InteractionFeedbackElement[];
    queueInteraction: (item: OwnedBuilding, x: number, y: number, randomizeOrigin: boolean, succeeded: boolean) => void;
};

export const InteractionFeedbackContext = createContext<InteractionFeedbackQueue | undefined>(undefined);

export function useInteractionFeedbackContext(): InteractionFeedbackQueue {
    const context = useContext(InteractionFeedbackContext);
    if (context === undefined) {
        throw new Error("Missing InteractionFeedbackProvider");
    }

    return context;
}
