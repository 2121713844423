import { useState } from "react";
import useInterval from "../../shared/useInterval";
import { InteractionFeedbackContext, InteractionFeedbackElement } from "./InteractionFeedbackContext";
import { v4 as uuidv4 } from 'uuid';

type Props = {
    children?: React.ReactNode
};

function randomNumber(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

export const InteractionFeedbackProvider: React.FC<Props> = ({ children }) => {
    const [items, setItems] = useState<InteractionFeedbackElement[]>([]);

    useInterval(() => {
        const now = Date.now();
        setItems(items.filter(item => (now - item.timestamp) < 1500));
    }, 1000);

    return (
        <InteractionFeedbackContext.Provider value={
            {
                interactions: items,
                queueInteraction: (newItem, x, y, randomizeOrigin, success) => {
                    setItems(items.concat({
                        target: newItem,
                        x: x + (randomizeOrigin ? randomNumber(-10, 10) : 0),
                        y: y + (randomizeOrigin ? randomNumber(-5, 5) : 0),
                        uuid: uuidv4(),
                        timestamp: Date.now(),
                        succeeded: success,
                    }));
                },
            }
        }>
            {children}
        </InteractionFeedbackContext.Provider>
    );
};
