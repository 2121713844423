import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { BuildingGroup } from '../../core/buildings/buildingTypes';
import { getBuildingData } from '../../core/buildings/buildingUtils';
import { ResourceProductionTableComponent } from '../shared/ResourceProductionTable';

type GameTooltipBuildingInteractionProps = {
    buildingGroup: BuildingGroup;
};

export const GameTooltipBuildingInteraction = ({ buildingGroup }: GameTooltipBuildingInteractionProps) => {
    const building = useAppSelector((state: RootState) => state.buildings[buildingGroup]);
    const buildingInteractionCache = useAppSelector((state: RootState) => state.cache.productionPerSecond.interactions);

    const buildingData = getBuildingData(building.type);

    if (buildingData.interaction) {
        switch (buildingData.interaction.type) {
            case 'generator':
            case 'building':
                const allRps = buildingInteractionCache.find(item => item.id === building.id)?.resources ?? [];
                return (
                    <>
                        Interacting with this building will generate:
                        <ResourceProductionTableComponent showHeaders={false} showPerSeconds={false} resourceAmounts={allRps} />
                    </>
                );
            case 'custom':
                return (<>{buildingData.interaction.tooltipDescription}</>);
        }
    }
    return <></>;
}
