import { ThunkAction, Action, configureStore } from '@reduxjs/toolkit';
import gameReducer from '../core/game';
import { enableMapSet } from "immer"
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { GameState } from '../core/gamestate';

enableMapSet()

const persistConfig: PersistConfig<GameState> = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['cache'],
};

const persistedReducer = persistReducer(persistConfig, gameReducer);

export const store = configureStore({
  reducer: persistedReducer
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
