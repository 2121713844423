import './shared.css'
import { ResourceAmount } from '../../core/resources/resourceTypes';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { isUnlockedAndVisible } from '../../utils/lock';
import { NumberFormatter } from '../../utils/formatter';
import { ResourceIcon } from '../resources/ResourceIcon';

export type ResourceCostButtonPartProps = {
    name: string;
    totalCost: ResourceAmount[];
};

export const ResourceCostButtonPart = ({ name, totalCost }: ResourceCostButtonPartProps) => {
    const resources = useAppSelector((state: RootState) => state.resources);
    const costBoxCharacters = ['(', ')'];

    return (
        <div className='ResourceCostButtonContainer'>
            {name}
            {totalCost.map((cost, index) => {
                return (
                    <div className='ResourceCostButtonEntry'>
                        {index === 0 && <>{costBoxCharacters[0]}</>}
                        {isUnlockedAndVisible(resources[cost.id]) ? (
                            <>
                                {NumberFormatter.formatStatic(cost.amount)}
                                <div style={{ width: '2px' }}> </div>
                                <ResourceIcon size='small' resource={cost.id} includeName />
                            </>
                        ) : (
                            <>
                                {resources[cost.id].amount >= cost.amount ? 'You are worthy' : 'You are not worthy'}
                            </>
                        )}
                        {(index + 1) <= (totalCost.length - 1) && <>,</>}
                        {index === (totalCost.length - 1) && <>{costBoxCharacters[1]}</>}
                    </div>
                );
            })}
        </div>
    );
};
