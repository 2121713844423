import { Draft } from "@reduxjs/toolkit";
import { assertUnreachable } from '../../utils/never';
import { GameState, getBuilding, getResource } from "../gamestate";
import { allUpgrades } from "./upgradeData";
import { AvailableUpgrade, Upgrade, UpgradeEffect, UpgradeType } from "./upgradeTypes";
import { OwnedResources } from "../resources/resourceTypes";
import { UnlockedIf, isUnlocked } from "../../utils/lock";

export const getUpgradeData = (type: UpgradeType): Upgrade => {
    return allUpgrades[type];
};

export const makeAvailableUpgrade = (type: UpgradeType): AvailableUpgrade => {
    return {
        id: type,
        unlocked: UnlockedIf(false),
        owned: false,
    };
};

export const applyUpgradeEffect = (state: Draft<GameState>, upgrade: UpgradeType): void => {
    const upgradeData = getUpgradeData(upgrade);

    function doUpgradeEffect(effect: UpgradeEffect) {
        switch (effect.type) {
            case 'immediate':
                effect.effect(state);
                break;
            case 'buildingUpgrade':
                const building = getBuilding(state, effect.group);
                if (building.type === effect.target) {
                    building.type = effect.replacement;
                }
                break;
            case 'resourceUpgrade':
                const resource = getResource(state, effect.group);
                if (resource.type === effect.target) {
                    resource.type = effect.replacement;
                }
                effect.buildingUpgrades.forEach(bu => doUpgradeEffect(bu));
                break;
            case 'multipliers':
                // No effect
                break;
            default:
                assertUnreachable(effect);
        }
    }
    doUpgradeEffect(upgradeData.effect);
};

export const canAffordUpgrade = (upgrade: AvailableUpgrade, ownedResources: OwnedResources): boolean => {
    if (isUnlocked(upgrade) && !upgrade.owned) {
        const upgradeData = getUpgradeData(upgrade.id);
        for (let cost of upgradeData.cost) {
            if (ownedResources[cost.id].amount < cost.amount) {
                return false;
            }
        }
        return true;
    }
    return false;
}