import './GameTooltip.css';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { DurationValue } from '../shared/DurationValue';
import { BuildingIcon } from '../buildings/BuildingIcon';
import { spellTypes } from '../../core/spells/spellTypes';
import { isUnlockedAndVisible } from '../../utils/lock';

export const GameTooltipHero = () => {
    const hero = useAppSelector((state: RootState) => state.hero);
    const spells = useAppSelector((state: RootState) => state.spells);
    const spellsArray = spellTypes.map(g => spells[g]);
    const unlockedSpells = spellsArray.filter(entry => isUnlockedAndVisible(entry));
    const hasAnySpell = unlockedSpells.length > 0;

    if (hero) {
        return (
            <div className='HeroTooltipContainer'>
                {(() => {
                    if (hero.recovery > 0) {
                        return (
                            <>
                                <div className='HeroTooltipTitle'>
                                    The <b>Hero</b> is recovering (<DurationValue duration={hero.recovery} />)
                                </div>
                                <div className='HeroTooltipDetails'>
                                    While your hero recovers, they cannot interact with buildings or use abilities.
                                </div>
                            </>
                        );
                    } else if (hero.target) {
                        return (
                            <>
                                <div className='HeroTooltipTitle'>
                                    The <b>Hero</b> is interacting with <BuildingIcon building={hero.target} size='small' includeName={true} />
                                </div>
                                <div className='HeroTooltipDetails'>
                                    The <b>Hero</b> interaction is similar to clicking on the "Interact" button once per second.
                                </div>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <div className='HeroTooltipTitle'>
                                    The <b>Hero</b> is idle
                                </div>
                                <div className='HeroTooltipDetails'>
                                    Move your <b>Hero</b> to a building so they can interact with that building for you
                                    {hasAnySpell ? (<>, or use some abilities.</>) : (<>.</>)}
                                </div>
                            </>
                        );
                    }
                })()}
            </div>
        );
    }
    return <></>;
}
