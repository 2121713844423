import { Building, BuildingResourceGenerator, BuildingTree, BuildingTreeBranch, cannotPurchase, consumed, generated, makeDynamicGenerator, makeFullGenerator, makeSimpleGenerator, makeStaticGenerator, makeTransformer, regularCost } from "./buildingTypes";
import { constName, simpleName } from "../shared/shared";
import { heroClick, interactionBuildingGenerate, interactionGenerator, interactionIncreaseResource } from "./buildingUtils";
import { GameState, getResource, getSpell } from "../gamestate";
import { isUnlocked } from "../../utils/lock";
import { spellTypes } from "../spells/spellTypes";
import { TemplateString, simpleString } from "../shared/templateString";
import { getCurrentPastryType } from "../resources/resourceUtils";


const wheatTree: BuildingTreeBranch = {
    wheat: {
        name: constName('Wheat field'),
        description: 'A simple wheat field.',
        cost: cannotPurchase(),
        maxAmount: 1,
        interaction: interactionIncreaseResource('wheat'),
        heroInteraction: heroClick(),
    },
    farm: {
        name: simpleName('Farm'),
        description: 'A farm next to a simple wheat field.',
        generator: makeSimpleGenerator('wheat', 0.1),
        cost: [regularCost('wheat', 5)],
        maxAmount: 20,
        interaction: interactionIncreaseResource('wheat'),
        heroInteraction: heroClick(),
    },
    irrigatedFarm: {
        name: simpleName('Irrigated farm'),
        description: 'A primitive irrigation system on a wheat field.',
        generator: makeTransformer(consumed('water', 0.3), generated('wheat', 0.3)),
        cost: [regularCost('anypastry', 0.6)],
        maxAmount: 100,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
};

const milkTree: BuildingTreeBranch = {
    milk: {
        name: constName('A loose cow'),
        description: 'A single cow chilling in the grass.',
        cost: cannotPurchase(),
        maxAmount: 1,
        interaction: interactionIncreaseResource('milk'),
        heroInteraction: heroClick(),
    },
    cows: {
        name: simpleName('Cow'),
        description: 'Some cows in a makeshift pen.',
        generator: makeSimpleGenerator('milk', 0.1),
        cost: [regularCost('milk', 5)],
        maxAmount: 20,
        interaction: interactionIncreaseResource('milk'),
        heroInteraction: heroClick(),
    },
    fedCows: {
        name: simpleName('Well fed cow'),
        description: 'Cows in a basic pen, eating organic.',
        generator: makeFullGenerator(
            [consumed('wheat', 0.05), consumed('water', 0.3)],
            [generated('milk', 0.2)]
        ),
        cost: [regularCost('anypastry', 0.6)],
        maxAmount: 100,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
};

const flourTree: BuildingTreeBranch = {
    grindstone: {
        name: simpleName('Grinding mill'),
        description: 'A manual tool to turn wheat into flour.',
        generator: makeTransformer(consumed('wheat', 0.3), generated('flour', 0.1)),
        cost: [
            regularCost('wheat', 10),
            regularCost('milk', 5),
        ],
        maxAmount: 20,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
    windmill: {
        name: simpleName('Windmill'),
        description: 'Use the power of the wind to turn wheat into flour.',
        generator: makeTransformer(consumed('wheat', 0.4), generated('flour', 0.3)),
        cost: [regularCost('money', 1)],
        maxAmount: 100,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
}

const butterTree: BuildingTreeBranch = {
    churn: {
        name: simpleName('Butter churn'),
        description: 'A manual tool to turn milk into butter.',
        generator: makeTransformer(consumed('milk', 0.3), generated('butter', 0.1)),
        cost: [
            regularCost('wheat', 10),
            regularCost('milk', 5),
        ],
        maxAmount: 20,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
    churnBarrel: {
        name: simpleName('Butter churn barrel'),
        description: 'A better tool to turn milk into butter.',
        generator: makeTransformer(consumed('milk', 0.4), generated('butter', 0.3)),
        cost: [regularCost('money', 1)],
        maxAmount: 100,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
};

const oven: Building = {
    name: simpleName('Primitive oven'),
    description: 'The simplest oven, good enough to bake some pastries.',
    generator: makeFullGenerator(
        [consumed('flour', 0.15), consumed('butter', 0.1)],
        [generated('anypastry', 0.1)],
    ),
    cost: [
        regularCost('flour', 5),
        regularCost('butter', 5),
    ],
    maxAmount: 20,
    interaction: interactionBuildingGenerate(),
    heroInteraction: heroClick(),
};

const coalOven: Building = {
    name: simpleName('Coal-powered oven'),
    description: 'A good oven that runs hot enough to bake pastries at a decent speed.',
    generator: makeFullGenerator(
        [consumed('flour', 0.15 * 1.5), consumed('butter', 0.1 * 1.5), consumed('egg', 0.05 * 1.5), consumed('coal', 0.1)],
        [generated('anypastry', 0.13)],
    ),
    cost: [regularCost('money', 2)],
    maxAmount: 50,
    interaction: interactionBuildingGenerate(),
    heroInteraction: heroClick(),
}

const ovenTree: BuildingTreeBranch = {
    oven: {
        name: simpleName('Primitive oven'),
        description: 'The simplest oven, good enough to bake some pastries.',
        generator: makeDynamicGenerator((state: GameState): BuildingResourceGenerator => {
            switch (getCurrentPastryType(state)) {
                case "pastry":
                    return makeStaticGenerator(
                        [consumed('flour', 0.15), consumed('butter', 0.1)],
                        [generated('anypastry', 0.1)]
                    );
                case "goldenpastry":
                    return makeStaticGenerator(
                        [consumed('flour', 0.15), consumed('butter', 0.1), consumed('egg', 0.05)],
                        [generated('anypastry', 0.1)]
                    );
                case "protocroissant":
                case "croissant":
                    return makeStaticGenerator(
                        [consumed('flour', 0.15), consumed('butter', 0.1), consumed('egg', 0.05), consumed('yeast', 0.01)],
                        [generated('anypastry', 0.1)]
                    );
            }
        }),
        cost: [
            regularCost('flour', 5),
            regularCost('butter', 5),
        ],
        maxAmount: 20,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },

    coalOven: {
        name: simpleName('Coal-powered oven'),
        description: 'A good oven that runs hot enough to bake pastries at a decent speed.',
        generator: makeDynamicGenerator((state: GameState): BuildingResourceGenerator => {
            switch (getCurrentPastryType(state)) {
                case "pastry":
                    return makeStaticGenerator(
                        [consumed('flour', 0.225), consumed('butter', 0.15), consumed('coal', 0.1)],
                        [generated('anypastry', 0.13)]
                    );
                case "goldenpastry":
                    return makeStaticGenerator(
                        [consumed('flour', 0.225), consumed('butter', 0.15), consumed('egg', 0.075), consumed('coal', 0.1)],
                        [generated('anypastry', 0.13)]
                    );
                case "protocroissant":
                    return makeStaticGenerator(
                        [consumed('flour', 0.225), consumed('butter', 0.15), consumed('egg', 0.075), consumed('yeast', 0.01), consumed('coal', 0.1)],
                        [generated('anypastry', 0.13)]
                    );
                case "croissant":
                    return makeStaticGenerator(
                        [consumed('flour', 0.2), consumed('butter', 0.175), consumed('egg', 0.06), consumed('yeast', 0.01), consumed('coal', 0.08)],
                        [generated('anypastry', 0.11)],
                    );
            }
        }),
        cost: [regularCost('money', 2)],
        maxAmount: 50,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
};

const waterTree: BuildingTreeBranch = {
    well: {
        name: simpleName('Water well'),
        description: 'A small well, stealing the ground\'s water.',
        generator: makeSimpleGenerator('water', 2.5),
        cost: [regularCost('anypastry', 5)],
        maxAmount: 100,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
};

const firstBakery: Building = {
    name: constName('Bakery rental'),
    description: 'A small bakery with quite a high rent, cutting heavily into the profits. But you gotta start somewhere...',
    generator: makeDynamicGenerator((state: GameState): BuildingResourceGenerator => {
        switch (getCurrentPastryType(state)) {
            case "pastry":
                return makeStaticGenerator([consumed('anypastry', 1)], [generated('money', 0.02)]);
            case "goldenpastry":
                return makeStaticGenerator([consumed('anypastry', 1)], [generated('money', 0.03)]);
            case "protocroissant":
                return makeStaticGenerator([consumed('anypastry', 1)], [generated('money', 0.06)]);
            case "croissant":
                return makeStaticGenerator([consumed('anypastry', 1)], [generated('money', 0.1)]);
        }
    }),
    cost: cannotPurchase(),
    maxAmount: 1,
};
const firstBakeryInteractable: Building = {
    ...firstBakery,
    interaction: interactionBuildingGenerate(),
    heroInteraction: heroClick(),
};

const bakeryTree: BuildingTreeBranch = {
    firstBakery: firstBakery,
    "firstBakery-interactable": firstBakeryInteractable,
};

const eggTree: BuildingTreeBranch = {
    coop: {
        name: simpleName('Chicken coop'),
        description: 'Some chickens clumped together.',
        generator: makeSimpleGenerator('egg', 1),
        cost: [regularCost('money', 2)],
        maxAmount: 100,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
};

const coalTree: BuildingTreeBranch = {
    coalMine: {
        name: simpleName('Coal mine'),
        description: 'Hire some local miners to collect coal for you.',
        generator: makeTransformer(consumed('money', 0.01), generated('coal', 1)),
        cost: [regularCost('money', 30)],
        maxAmount: 20,
        interaction: interactionIncreaseResource('coal'),
        heroInteraction: heroClick(),
    },
};

const studyTree: BuildingTreeBranch = {
    study: {
        name: constName("Study"),
        description: 'A place for the hero to learn new abilities.',
        cost: cannotPurchase(),
        maxAmount: 1,
        dynamicDescription: (state: GameState): TemplateString => {
            for (let spell of spellTypes) {
                if (!isUnlocked(getSpell(state, spell))) {
                    return simpleString('You still have things to learn...');
                }
            }
            return simpleString('You know everything.');
        },
        heroInteraction: {
            type: 'custom',
            action: (state, _self, _tickMultiplier) => {
                const resource = getResource(state, 'knowledge');
                if (isUnlocked(resource)) {
                    resource.amount += _tickMultiplier;
                }
            },
            tooltipDescription: 'It is unknown what the hero actually does here',
        },
    },
};

const yeastJar: Building = {
    name: simpleName("Yeast jar"),
    description: 'A big jar where yeast can reproduce. The more yeast you have, the faster it reproduces.',
    cost: cannotPurchase(),
    maxAmount: 1,
    generator: makeDynamicGenerator((state: GameState): BuildingResourceGenerator => {
        const jars = state.buildings.jar.amount;
        const currentYeast = state.resources.yeast.amount;
        const produced = Math.max(0.01, Math.log10(currentYeast * Math.sqrt(jars)) / 10);
        return makeStaticGenerator([], [generated('yeast', produced)]);
    }),
    interaction: interactionGenerator(makeStaticGenerator([], [generated('yeast', 0.05)])),
    heroInteraction: heroClick(),
};

const yeastTree: BuildingTreeBranch = {
    jar: yeastJar,
    jars: {
        ...yeastJar,
        description: 'Big jars where yeast can reproduce. The more yeast you have, the faster it reproduces.',
        cost: [regularCost('yeast', 100), regularCost('money', 50)],
        maxAmount: 20,
    },
};

const boilerTree: BuildingTreeBranch = {
    boiler: {
        name: simpleName('Steam boiler'),
        description: 'Heats up water to produce steam.',
        cost: [regularCost('money', 30)],
        maxAmount: 100,
        generator: makeFullGenerator([consumed('coal', 0.25), consumed('water', 1)], [generated('steam', 1)]),
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    }
};

const cacaoTree: BuildingTreeBranch = {
    cacaotree: {
        name: constName('Cacao tree'),
        description: 'The seed you planted grew into a nice cacao tree in record time.',
        cost: cannotPurchase(),
        maxAmount: 1,
        interaction: interactionIncreaseResource('cacao'),
        heroInteraction: heroClick(),
    },
    cacaoFarm: {
        name: simpleName('Cacao tree farm'),
        description: 'Several cacao trees in a rudimentary farm',
        generator: makeSimpleGenerator('cacao', 0.02),
        cost: [regularCost('cacao', 5), regularCost('money', 10)],
        maxAmount: 100,
        interaction: interactionIncreaseResource('cacao'),
        heroInteraction: heroClick(),
    },
};

const sugarTree: BuildingTreeBranch = {
    sugarcane: {
        name: constName('Sugarcane'),
        description: 'The seed you planted grew into a nice sugarcane in record time.',
        cost: cannotPurchase(),
        maxAmount: 1,
        interaction: interactionIncreaseResource('sugar'),
        heroInteraction: heroClick(),
    },
    sugarFarm: {
        name: simpleName('Sugarcane plantation'),
        description: 'A nice field of sugarcane with a plentiful supply of water.',
        generator: makeTransformer(consumed('water', 1), generated('sugar', 0.1)),
        cost: [regularCost('sugar', 5), regularCost('money', 10)],
        maxAmount: 100,
        interaction: interactionIncreaseResource('sugar'),
        heroInteraction: heroClick(),
    },
};

const mixerTree: BuildingTreeBranch = {
    mixer: {
        name: simpleName('Chocolate mixer'),
        description: 'A collection of tools to turn cocoa and sugar into chocolate.',
        generator: makeFullGenerator([consumed('cacao', 2), consumed('sugar', 0.4)], [generated('chocolate', 1)]),
        cost: [regularCost('money', 100)],
        maxAmount: 20,
        interaction: interactionBuildingGenerate(),
        heroInteraction: heroClick(),
    },
}

export const allBuildings: BuildingTree = {
    farm: wheatTree,
    cows: milkTree,
    coop: eggTree,
    well: waterTree,
    grindstone: flourTree,
    churn: butterTree,
    oven: ovenTree,
    bakery: bakeryTree,
    coal: coalTree,
    study: studyTree,
    jar: yeastTree,
    boiler: boilerTree,
    cacaotree: cacaoTree,
    sugarcane: sugarTree,
    mixer: mixerTree,
};
