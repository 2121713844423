import { Draft } from "@reduxjs/toolkit";
import { BuildingGroup, OwnedBuilding, OwnedBuildings } from './buildings/buildingTypes';
import { OwnedResource, OwnedResources, ResourceGroup } from './resources/resourceTypes';
import { AvailableUpgrade, UpgradeType } from './upgrades/upgradeTypes';
import { makeAvailableUpgrade } from './upgrades/upgradeUtils';
import { CurrentHero } from './hero';
import { GameCache } from './cache/cache';
import { AvailableSpell, AvailableSpells, SpellType } from './spells/spellTypes';
import { Buff } from './buffs/buffTypes';

export const ticksPerSeconds = 10;
export const tickInterval = 1000 / ticksPerSeconds;

export type GameState = {
    resources: OwnedResources;
    buildings: OwnedBuildings;
    upgrades: AvailableUpgrade[];
    spells: AvailableSpells;
    hero?: CurrentHero;

    buffs: Buff[];

    cache: GameCache;

    tickid: string;
}

function getIdentifiable<U, T extends { id: U }>(state: Draft<T>[], id: U, factory: (id: U) => Draft<T>): Draft<T> {
    let res = state.find((item) => item.id === id);
    if (res === undefined) {
        const index = state.push(factory(id)) - 1;
        return state[index];
    } else {
        return res;
    }
}

export const getResource = (state: Draft<GameState>, id: ResourceGroup): Draft<OwnedResource> => {
    return state.resources[id];
}

export const getBuilding = (state: Draft<GameState>, id: BuildingGroup): Draft<OwnedBuilding> => {
    return state.buildings[id];
}

export const getSpell = (state: Draft<GameState>, id: SpellType): Draft<AvailableSpell> => {
    return state.spells[id];
}

export const getUpgrade = (state: Draft<GameState>, id: UpgradeType): Draft<AvailableUpgrade> => {
    return getIdentifiable(state.upgrades, id, makeAvailableUpgrade);
}
