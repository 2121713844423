import './shared.css'
import { ticksPerSeconds } from '../../core/gamestate';

export type DurationValueProps = {
    duration: number;
};

type DurationComponent = {
    amount: string;
    unit: string;
}

function stringifyDuration(ticks: number): DurationComponent[] {
    const seconds = Math.ceil(ticks / ticksPerSeconds);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    var result: DurationComponent[] = [];

    if (hours > 0) {
        result.push({
            amount: hours.toFixed(),
            unit: 'h',
        });
    }

    if (minutes % 60 > 0) {
        result.push({
            amount: (minutes % 60).toFixed(),
            unit: 'min',
        });
    }

    if (seconds % 60 > 0) {
        result.push({
            amount: (seconds % 60).toFixed(),
            unit: 'sec',
        });
    }

    return result;
}

export const DurationValue = ({ duration }: DurationValueProps) => {
    const durationComponents = stringifyDuration(duration);
    if (durationComponents.length > 0) {
        return (
            <>
                {durationComponents.map((component, index) => {
                    return (
                        <>
                            <span className='DurationValue'>{component.amount}</span>
                            <span className='DurationUnit'>{component.unit}</span>
                            {index + 1 < durationComponents.length && <> </>}
                        </>
                    );
                })}
            </>
        );
    } else {
        return <span className='NoDuration'>-</span>;
    }
};
