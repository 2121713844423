import { Accordion, Button, Card } from 'react-bootstrap';
import { useAppDispatch } from '../app/hooks';
import { debugDoubleResources, debugGrantAllSpells, debugGrantHero, debugRefreshCooldowns, debugUnlockAllAndGainResources, hardReset } from '../core/game';

export const DebugPanelComponent = () => {
    const dispatch = useAppDispatch();

    return (
        <Card className='Card'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Debug</Accordion.Header>
                    <Accordion.Body className='DebugPanel'>
                        <Button variant='secondary' onClick={() => dispatch(debugDoubleResources())}>Double resources</Button>
                        <Button variant='secondary' onClick={() => dispatch(debugGrantHero())}>Grant hero</Button>
                        <Button variant='secondary' onClick={() => dispatch(debugUnlockAllAndGainResources())}>Unlock everything</Button>
                        <Button variant='secondary' onClick={() => dispatch(hardReset())}>Hard reset</Button>
                        <Button variant='secondary' onClick={() => dispatch(debugRefreshCooldowns())}>Refresh CDs</Button>
                        <Button variant='secondary' onClick={() => dispatch(debugGrantAllSpells())}>Grant spells</Button>
                        {/* <Button variant='secondary' onClick={() => dispatch()}></Button> */}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card>
    );
}
