import './Upgrades.css'

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { getUpgradeData } from '../../core/upgrades/upgradeUtils';
import { UpgradeComponent } from './UpgradeComponent';
import { isUnlockedAndVisible } from '../../utils/lock';

export function UpgradesContainerComponent() {
    const upgrades = useAppSelector((state: RootState) => state.upgrades);

    const ownedUpgrades = upgrades.filter((entry) => isUnlockedAndVisible(entry) && !entry.owned && getUpgradeData(entry.id).effect.type !== 'buildingUpgrade');

    return (
        <div className='UpgradesContainer'>
            <p>Upgrades</p>
            {ownedUpgrades.map((entry) => {
                return (<UpgradeComponent upgrade={entry} key={entry.id} />);
            })}
        </div>
    );
}
