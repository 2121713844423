import { Draft } from "@reduxjs/toolkit";
import { GameState } from "../gamestate";
import { unlockAndOwnBuilding, unlockHiddenResource, unlockResource, unlockSpell } from "../unlocks/unlockUtils";
import { AbilitiesUpgradesTypeInternal, BuildingUpgradeTypeInternal, fixedCost, makeImmediateUpgradeEffect, makeUpgradeBuildingTransition, makeUpgradeMultiplier, makeUpgradeMultipliers, makeUpgradeResourceTransition, RegularUpgradeTypeInternal, singleCost, staticMultiplier, Upgrade, UpgradeType } from "./upgradeTypes";
import { newHero } from "../hero";
import { buildingReferenceString, plainString, resourceAmountString, resourceReferenceString, simpleString, makeTemplateString, templateStringFormat } from "../shared/templateString";

type BuildingUpgradeData = {
    [K in BuildingUpgradeTypeInternal]: Upgrade
}
type RegularUpgradeData = {
    [K in RegularUpgradeTypeInternal]: Upgrade
}
type AbilitiesUpgradeData = {
    [K in AbilitiesUpgradesTypeInternal]: Upgrade
}
type UpgradeData = {
    [K in UpgradeType]: Upgrade
}

const buildingUpgrades: BuildingUpgradeData = {
    wheatToFarm: {
        name: 'Build a farm',
        description: simpleString('You like this wheat field, make it yours and automate production'),
        tag: 'regular',
        cost: singleCost('wheat', 5),
        effect: makeUpgradeBuildingTransition('farm', 'wheat', 'farm')
    },
    milkToCowPen: {
        name: 'Build a cow pen',
        description: simpleString('You like this cow, make it yours and automate production'),
        tag: 'regular',
        cost: singleCost('milk', 5),
        effect: makeUpgradeBuildingTransition('cows', 'milk', 'cows')
    },

    farmsToIrrigatedFarms: {
        name: 'Upgrade to irrigated farms',
        description: templateStringFormat('Installing an irrigation system will help with $wheat production'),
        tag: 'regular',
        cost: singleCost('anypastry', 30),
        effect: makeUpgradeBuildingTransition('farm', 'farm', 'irrigatedFarm'),
    },
    cowsToFedCows: {
        name: 'Upgrade to well fed cows',
        description: simpleString('Feed your cows properly'),
        tag: 'regular',
        cost: singleCost('anypastry', 40),
        effect: makeUpgradeBuildingTransition('cows', 'cows', 'fedCows'),
    },
    bakeryInteractable: {
        name: 'Fine, I\'ll do it myself',
        description: templateStringFormat('Lets you click the @bakery to sell $anypastry manually'),
        tag: 'regular',
        cost: singleCost('money', 2),
        effect: makeUpgradeBuildingTransition('bakery', 'firstBakery', 'firstBakery-interactable'),
    },
    grindstoneToWindmill: {
        name: 'Upgrade to windmill',
        description: templateStringFormat('By harnessing the power of the wind, you can produce $flour faster and more efficiently'),
        tag: 'regular',
        cost: singleCost('anypastry', 80),
        effect: makeUpgradeBuildingTransition('grindstone', 'grindstone', 'windmill'),
    },
    churnToBarrel: {
        name: 'Upgrade to barrels',
        description: templateStringFormat('Using a large barrel lets you produce $butter faster and more efficiently'),
        tag: 'regular',
        cost: singleCost('anypastry', 80),
        effect: makeUpgradeBuildingTransition('churn', 'churn', 'churnBarrel'),
    },
    ovenToCoalOven: {
        name: 'Upgrade to Coal ovens',
        description: templateStringFormat('Use some $coal to power your @oven, producing $anypastry faster but slightly less efficiently'),
        tag: 'regular',
        cost: singleCost('money', 50),
        effect: makeUpgradeBuildingTransition('oven', 'oven', 'coalOven'),
    },

    cacaoToFarm: {
        name: 'Build a farm',
        description: templateStringFormat('You\'re not gonna keep picking these up yourself'),
        tag: 'regular',
        cost: singleCost('money', 50),
        effect: makeUpgradeBuildingTransition('cacaotree', 'cacaotree', 'cacaoFarm'),
    },
    sugarcaneToPlantation: {
        name: 'Setup a plantation',
        description: templateStringFormat('You\'re going to need more sugar than that'),
        tag: 'regular',
        cost: singleCost('money', 50),
        effect: makeUpgradeBuildingTransition('sugarcane', 'sugarcane', 'sugarFarm'),
    }
}

const regularUpgrades: RegularUpgradeData = {
    unlockFirstBakery: {
        name: 'Baby\'s first bakery',
        description: templateStringFormat('Rent your very first @bakery to make money off your $anypastry!'),
        tag: 'important',
        cost: singleCost('anypastry', 100),
        effect: makeImmediateUpgradeEffect((state: Draft<GameState>) => {
            unlockResource(state, 'money');
            unlockAndOwnBuilding(state, 'bakery');
        }),
    },
    clickDoubled1: {
        name: 'Double click',
        description: simpleString('Clicking is twice as effective'),
        tag: 'regular',
        cost: singleCost('wheat', 30),
        effect: makeUpgradeMultiplier('interactions', 2),
    },
    clickDoubled2: {
        name: 'Quadruple click',
        description: simpleString('Clicking is twice as effective'),
        tag: 'regular',
        cost: singleCost('milk', 30),
        effect: makeUpgradeMultiplier('interactions', 2),
    },
    clickConsumedHalved: {
        name: 'Economical click',
        description: simpleString('Clicking resource cost halved'),
        tag: 'regular',
        cost: singleCost('anypastry', 10),
        effect: makeUpgradeMultiplier('interactions/consumed', 0.5),
    },
    farmsProductionDoubled: {
        name: 'Fertilizer',
        description: templateStringFormat('@farm produce twice as much'),
        tag: 'regular',
        cost: singleCost('wheat', 50),
        effect: makeUpgradeMultiplier('farm', 2),
    },
    cowsProductionDoubled: {
        name: 'Super Cows',
        description: templateStringFormat('@cows produce twice as much'),
        tag: 'regular',
        cost: singleCost('milk', 50),
        effect: makeUpgradeMultiplier('cows', 2),
    },
    grindstoneProductionDoubled: {
        name: 'Grinding hard',
        description: templateStringFormat('@grindstone produce 50% more at no additional cost!'),
        tag: 'regular',
        cost: singleCost('flour', 50),
        effect: makeUpgradeMultiplier('grindstone/produced', 1.5),
    },
    churnProductionDoubled: {
        name: 'Churning hard',
        description: templateStringFormat('@churn produce 50% more at no additional cost!'),
        tag: 'regular',
        cost: singleCost('butter', 50),
        effect: makeUpgradeMultiplier('churn/produced', 1.5),
    },
    ovenProductionUpgrade: {
        name: 'Perfect temperature',
        description: templateStringFormat('@oven produce twice as much, while only using 50% more resources'),
        tag: 'regular',
        cost: singleCost('anypastry', 50),
        effect: makeUpgradeMultipliers([
            staticMultiplier('oven/produced', 2),
            staticMultiplier('oven/consumed', 1.5),
        ]),
    },


    unlockHero: {
        name: 'The HERO',
        description: templateStringFormat('Unlock the {Hero}, clicking on stuff for you'),
        tag: 'important',
        cost: singleCost('anypastry', 20),
        effect: makeImmediateUpgradeEffect((state: Draft<GameState>) => {
            if (!state.hero) {
                state.hero = newHero();
            }
        }),
    },

    fasterBakery: {
        name: 'My first €!',
        description: templateStringFormat('Having this upgrade makes the @bakery twice as fast'),
        tag: 'regular',
        cost: singleCost('money', 1),
        effect: makeUpgradeMultiplier('bakery', 2),
    },

    produceGoldenPastries: {
        name: 'A golden finish',
        description: templateStringFormat('Brushing $egg on top of your $anypastry makes it much tastier. Pastries now require $egg to produce, but sell for more'),
        tag: 'important',
        cost: singleCost('egg', 50),
        effect: makeUpgradeResourceTransition('anypastry', 'pastry', 'goldenpastry', []),
    },
    discoveryYeast: {
        name: 'A curious organism',
        description: templateStringFormat('You discover yeast, a curious organism that might help you bake better pastries'),
        tag: 'regular',
        cost: singleCost('money', 5),
        effect: makeImmediateUpgradeEffect((state: Draft<GameState>) => {
            unlockResource(state, 'yeast');
            unlockAndOwnBuilding(state, 'jar');
        }),
    },
    produceProtocroissants: {
        name: 'Protocroissants',
        description: templateStringFormat('It turns out that cooking pastries with yeast make them rise and taste delicious. Pastries are replaced by protocroissants, requiring $yeast to produce, but selling for more'),
        tag: 'important',
        cost: singleCost('yeast', 30),
        effect: makeUpgradeResourceTransition('anypastry', 'goldenpastry', 'protocroissant', []),
    },

    clickProductionDoubled: {
        name: 'Savoir faire',
        description: simpleString('Clicking produces twice as much, at no additional cost!'),
        tag: 'regular',
        cost: singleCost('anypastry', 100),
        effect: makeUpgradeMultiplier('interactions/produced', 2),
    },

    fasterOvens: {
        name: 'Faster ovens',
        description: templateStringFormat('@oven produce 50% more'),
        tag: 'regular',
        cost: singleCost('money', 10),
        effect: makeUpgradeMultiplier('oven', 1.5),
    },

    laminationTechnique: {
        name: 'Lamination technique',
        description: templateStringFormat('By layering $butter many times, you can make a flaky and delicious pastry. Protocroissants are replaced with Croissants, requiring more $butter and more time to bake, but selling for more'),
        tag: 'important',
        cost: [fixedCost('money', 500), fixedCost('butter', 2000)],
        effect: makeUpgradeResourceTransition('anypastry', 'protocroissant', 'croissant', []),
    },

    unlockStudy: {
        name: 'Clicking is not enough',
        description: templateStringFormat('Unlocks the @study, where your {Hero} can learn new abilities'),
        tag: 'important',
        cost: singleCost('money', 200),
        effect: makeImmediateUpgradeEffect((state) => {
            unlockHiddenResource(state, 'knowledge');
            unlockAndOwnBuilding(state, 'study');
        }),
    },

    fasterYeast: {
        name: 'Faster yeast',
        description: templateStringFormat('$yeast reproduces 50% more rapidly'),
        tag: 'regular',
        cost: singleCost('money', 50),
        effect: makeUpgradeMultiplier('jar', 1.5),
    },

    manyJars: {
        name: 'Just add more jars',
        description: templateStringFormat('You consult an expert to determine how to produce more $yeast. The answer will shock you!'),
        tag: 'regular',
        cost: singleCost('money', 100),
        effect: makeImmediateUpgradeEffect((state: Draft<GameState>) => {
            state.buildings.jar.type = 'jars';
        }),
    },

    vacation: {
        name: 'Go on vacation',
        description: templateStringFormat('You go on vacation far away from home. You bring back some interesting seeds.'),
        tag: 'important',
        cost: singleCost('money', 2000),
        effect: makeImmediateUpgradeEffect((state: Draft<GameState>) => {
            unlockResource(state, 'cacao');
            unlockResource(state, 'sugar');
            unlockAndOwnBuilding(state, 'cacaotree');
            unlockAndOwnBuilding(state, 'sugarcane')
        }),
    }

    // test: {
    //     name: 'Test dynamic mult',
    //     description: '+10% bakery production per oven',
    //     cost: singleCost('money', 1),
    //     effect: makeDynamicUpgradeMultiplier('bakery/produced', (state: GameState): number => {
    //         return 1 + (0.1 * getBuilding(state, 'oven').amount);
    //     }),
    // },
};

const abilitiesData: AbilitiesUpgradeData = {
    unlockHardWork: {
        name: 'Working hard',
        description: templateStringFormat('Unlock the #hardwork ability, trading your {Hero}\'s time for $money'),
        tag: 'ability',
        cost: singleCost('knowledge', 100),
        effect: makeImmediateUpgradeEffect((state) => {
            unlockSpell(state, 'hardwork');
        }),
    },

    unlockOvertime: {
        name: 'Working overtime',
        description: templateStringFormat('Unlock the #overtime ability, allowing your {Hero interactions} to be more powerful, but disabling {manual clicks}'),
        tag: 'ability',
        cost: singleCost('knowledge', 200),
        effect: makeImmediateUpgradeEffect(state => {
            unlockSpell(state, 'overtime');
        }),
    },

    unlockSales: {
        name: 'Discounting prices',
        description: templateStringFormat('Unlock the #sales ability, allowing you to sell $anypastry much faster for but less profit'),
        tag: 'ability',
        cost: singleCost('knowledge', 200),
        effect: makeImmediateUpgradeEffect(state => {
            unlockSpell(state, 'sales');
        }),
    },

    unlockStrike: {
        name: 'Going on strike',
        description: templateStringFormat('Unlock the #strike ability, stopping a random building but making all others produce more'),
        tag: 'ability',
        cost: singleCost('knowledge', 300),
        effect: makeImmediateUpgradeEffect(state => {
            unlockSpell(state, 'strike');
        }),
    }
}

export const allUpgrades: UpgradeData = { ...buildingUpgrades, ...regularUpgrades, ...abilitiesData };
