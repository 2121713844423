import { BuildingGroup } from "../../core/buildings/buildingTypes";
import { SpellType } from "../../core/spells/spellTypes";
import { UpgradeType } from "../../core/upgrades/upgradeTypes";

export const gameTooltipId = 'global';

export type GameTooltipTargetBaseTypes =
    | `building-buy/${BuildingGroup}`
    | `building-interact/${BuildingGroup}`
    | `building-hero/${BuildingGroup}`
    | `upgrade/${UpgradeType}`
    | `spell/${SpellType}`
    | `static/${GameTooltipTargetStaticTypes}`;

export type GameTooltipTargetStaticTypes =
    | 'hero';

export type GameTooltipTarget =
    GameTooltipTargetBaseTypes
    | `${GameTooltipTargetBaseTypes}/disabled`;

function disabledPath(disabled: boolean): string {
    return disabled ? "/disabled" : "";
}

export function buildingBuyTooltipTarget(item: BuildingGroup, disabled: boolean): GameTooltipTarget {
    return `building-buy/${item}${disabledPath(disabled)}` as GameTooltipTarget;
}

export function buildingInteractTooltipTarget(item: BuildingGroup, disabled: boolean): GameTooltipTarget {
    return `building-interact/${item}${disabledPath(disabled)}` as GameTooltipTarget;
}

export function buildingHeroInteractTooltipTarget(item: BuildingGroup, disabled: boolean): GameTooltipTarget {
    return `building-hero/${item}${disabledPath(disabled)}` as GameTooltipTarget;
}

export function upgradeTooltipTarget(item: UpgradeType, disabled: boolean): GameTooltipTarget {
    return `upgrade/${item}${disabledPath(disabled)}` as GameTooltipTarget;
}

export function spellTooltipTarget(item: SpellType, disabled: boolean): GameTooltipTarget {
    return `spell/${item}${disabledPath(disabled)}` as GameTooltipTarget;
}

export function heroTooltipTarget(disabled: boolean): GameTooltipTarget {
    return `static/hero${disabledPath(disabled)}` as GameTooltipTarget;
}
