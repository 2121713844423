import { Draft } from "@reduxjs/toolkit";
import { Unlockable } from "../../utils/lock";
import { BuildingGroup, BuildingType } from "../buildings/buildingTypes";
import { GameState } from "../gamestate";
import { Multiplier, MultiplierType, StaticMultiplier } from "../multipliers/multiplierTypes";
import { ResourceType, ResourceGroup, ResourceAmount } from "../resources/resourceTypes";
import { TemplateString } from "../shared/templateString";

export const buildingUpgradesInternal = [
    'wheatToFarm',
    'milkToCowPen',
    'farmsToIrrigatedFarms',
    'cowsToFedCows',
    'bakeryInteractable',
    'grindstoneToWindmill',
    'churnToBarrel',
    'ovenToCoalOven',
    'cacaoToFarm',
    'sugarcaneToPlantation',
] as const;

export const regularUpgradesInternal = [
    'unlockFirstBakery',
    'clickDoubled1',
    'clickDoubled2',
    'clickConsumedHalved',
    'farmsProductionDoubled',
    'cowsProductionDoubled',
    'grindstoneProductionDoubled',
    'churnProductionDoubled',
    'ovenProductionUpgrade',
    'unlockHero',
    'fasterBakery',
    'produceGoldenPastries',
    'discoveryYeast',
    'produceProtocroissants',
    'clickProductionDoubled',
    'fasterOvens',
    'laminationTechnique',
    'unlockStudy',
    'fasterYeast',
    'manyJars',
    'vacation',
] as const;

export const abilitiesUpgradesInternal = [
    'unlockHardWork',
    'unlockOvertime',
    'unlockSales',
    'unlockStrike',
] as const;

export const upgrades = [...buildingUpgradesInternal, ...regularUpgradesInternal, ...abilitiesUpgradesInternal] as const;

export type BuildingUpgradeTypeInternal = typeof buildingUpgradesInternal[number];
export type RegularUpgradeTypeInternal = typeof regularUpgradesInternal[number];
export type AbilitiesUpgradesTypeInternal = typeof abilitiesUpgradesInternal[number];
export type UpgradeType = typeof upgrades[number];

export const upgradeTags = [
    'regular',
    'important',
    'ability',
] as const;

export type UpgradeTag = typeof upgradeTags[number];

export type UpgradeEffectImmediate = {
    type: 'immediate';
    effect: (state: Draft<GameState>) => void;
}

export type UpgradeEffectMultipliers = {
    type: 'multipliers';
    multipliers: Multiplier[];
}

export type UpgradeEffectBuildingTransition = {
    type: 'buildingUpgrade';
    group: BuildingGroup;
    target: BuildingType;
    replacement: BuildingType;
}

export type UpgradeEffectResourceTransition = {
    type: 'resourceUpgrade';
    group: ResourceGroup;
    target: ResourceType;
    replacement: ResourceType;
    buildingUpgrades: UpgradeEffectBuildingTransition[];
}

export type UpgradeEffect = UpgradeEffectImmediate | UpgradeEffectMultipliers | UpgradeEffectBuildingTransition | UpgradeEffectResourceTransition;

export type Upgrade = {
    name: string;
    description: TemplateString;
    tag: UpgradeTag;
    cost: ResourceAmount[];

    effect: UpgradeEffect;
}

export type AvailableUpgrade = {
    id: UpgradeType;
    unlocked: Unlockable;
    owned: boolean;
}

export const makeImmediateUpgradeEffect = (effect: (state: Draft<GameState>) => void): UpgradeEffectImmediate => {
    return {
        type: 'immediate',
        effect: effect,
    }
};

type StaticMultiplierData = Omit<StaticMultiplier, 'dynamic'>;
// type DynamicMultiplierData = Omit<DynamicMultiplier, 'dynamic'>;

export const makeUpgradeMultiplier = (type: MultiplierType, amount: number): UpgradeEffectMultipliers => {
    return makeUpgradeMultipliers([{ type: type, amount: amount }]);
};

export const makeUpgradeMultipliers = (multipliers: StaticMultiplierData[]): UpgradeEffectMultipliers => {
    return {
        type: 'multipliers',
        multipliers: multipliers.map(m => ({
            dynamic: false,
            ...m
        })),
    };
};

export const makeUpgradeMultiplierOnTargets = (targets: MultiplierType[], amount: number): UpgradeEffectMultipliers => {
    return {
        type: 'multipliers',
        multipliers: targets.map(target => {
            return {
                dynamic: false,
                type: target,
                amount: amount
            };
        }),
    };
};

export const makeDynamicUpgradeMultiplier = (type: MultiplierType, resolve: (state: GameState) => number): UpgradeEffectMultipliers => {
    return {
        type: 'multipliers',
        multipliers: [{
            dynamic: true,
            ...{ type: type, resolve: resolve }
        }],
    };
};


export const makeUpgradeBuildingTransition = (group: BuildingGroup, from: BuildingType, to: BuildingType): UpgradeEffectBuildingTransition => {
    return {
        type: 'buildingUpgrade',
        group: group,
        target: from,
        replacement: to,
    };
};

export const makeUpgradeResourceTransition = (group: ResourceGroup, from: ResourceType, to: ResourceType, buildings: UpgradeEffectBuildingTransition[]): UpgradeEffectResourceTransition => {
    return {
        type: 'resourceUpgrade',
        group: group,
        target: from,
        replacement: to,
        buildingUpgrades: buildings,
    };
};

export const fixedCost = (id: ResourceGroup, amount: number): ResourceAmount => {
    return {
        id: id,
        amount: amount,
    };
};

export const singleCost = (id: ResourceGroup, amount: number): ResourceAmount[] => {
    return [fixedCost(id, amount)];
};

export const staticMultiplier = (type: MultiplierType, amount: number): StaticMultiplierData => {
    return {
        type: type,
        amount: amount,
    }
};
