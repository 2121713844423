import './GameTooltip.css';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { SpellType } from '../../core/spells/spellTypes';
import { getSpellData } from '../../core/spells/spellUtils';
import { TemplateStringComponent } from '../shared/TemplateStringComponent';
import { DurationValue } from '../shared/DurationValue';
import { getSpell } from '../../core/gamestate';

type GameTooltipSpellProps = {
    spellType: SpellType;
};

export const GameTooltipSpell = ({ spellType }: GameTooltipSpellProps) => {
    const state = useAppSelector((state: RootState) => state);

    const spellData = getSpellData(spellType);
    const templateDescription = spellData.getDescription(state);

    const availableSpell = getSpell(state, spellType);
    const isOnCooldown = availableSpell.cooldown > 0;
    const durationLeft = availableSpell.status.active ? availableSpell.status.duration : 0;
    const isHeroRecovering = state.hero && state.hero.recovery > 0;

    const shouldShowBottomContainer = durationLeft > 0 || isOnCooldown || isHeroRecovering;

    const makeDurationDisplay = (name: string, value: number): JSX.Element => {
        return <span>
            <span className='SpellValueName'>{name}: </span>
            <DurationValue duration={value} />
        </span>;
    };

    return (
        <div className='SpellTooltipContainer'>
            <span className='SpellName'>{spellData.name}</span>
            <TemplateStringComponent content={templateDescription} />
            <div className='SpellTooltipValuesContainer'>
                {spellData.effect.type === 'temporary' && makeDurationDisplay("Duration", spellData.effect.duration)}
                {makeDurationDisplay("Cooldown", spellData.cooldown)}
                {makeDurationDisplay("Recovery", spellData.recovery)}
            </div>
            {shouldShowBottomContainer && (
                <div className='SpellTooltipCurrentTimeValuesContainer'>
                    <hr />
                    {durationLeft > 0 && <div className='SpellCurrentDuration'>Remaining duration: <DurationValue duration={durationLeft} /></div>}
                    {isOnCooldown && <div className='SpellCurrentCooldown'>Current cooldown: <DurationValue duration={availableSpell.cooldown} /></div>}
                    {isHeroRecovering && (
                        <div className='HeroRecovering'>
                            The hero is recovering: <DurationValue duration={state.hero!.recovery} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
