import { Draft } from "@reduxjs/toolkit";
import { GameState, getBuilding } from "../gamestate";
import { BuildingGroup, buildingGroups } from "../buildings/buildingTypes";
import { isUnlocked, setUnlocked } from "../../utils/lock";
import { ResourceGroup } from "../resources/resourceTypes";
import { allOf, conditionAlways, conditionHasBuilding, conditionHasResource, conditionHasUpgrade, conditionNever, UnlockCondition, unlockResource } from "./unlockUtils";

type BuildingUnlockData = {
    condition: UnlockCondition,
    resource?: ResourceGroup,
    owned?: boolean,
};

type BuildingsUnlockRules = {
    [K in BuildingGroup]: BuildingUnlockData
};

const buildingsUnlocks: BuildingsUnlockRules = {
    bakery: {
        condition: conditionNever(), // Manual unlock in upgrades
    },
    farm: {
        resource: 'wheat',
        condition: conditionAlways(),
        owned: true,
    },
    cows: {
        resource: 'milk',
        condition: conditionHasResource('wheat', 50),
        owned: true,
    },
    coop: {
        resource: 'egg',
        condition: allOf([
            conditionHasUpgrade('bakeryInteractable'),
            conditionHasResource('money', 5),
        ]),
    },
    well: {
        resource: 'water',
        condition: conditionHasResource('anypastry', 10),
    },
    grindstone: {
        resource: 'flour',
        condition: conditionHasBuilding('cows', 5),
    },
    churn: {
        resource: 'butter',
        condition: conditionHasResource('flour', 20),
    },
    oven: {
        resource: 'anypastry',
        condition: allOf([
            conditionHasBuilding('grindstone', 5),
            conditionHasBuilding('churn', 5),
        ]),
    },
    coal: {
        resource: 'coal',
        condition: allOf([
            conditionHasUpgrade('produceProtocroissants'),
            conditionHasResource('money', 20),
        ]),
    },
    study: {
        condition: conditionNever(),
    },
    jar: {
        condition: conditionNever(),
    },
    boiler: {
        condition: conditionNever(),
    },
    cacaotree: {
        condition: conditionNever(),
    },
    sugarcane: {
        condition: conditionNever(),
    },
    mixer: {
        resource: 'chocolate',
        condition: allOf([
            conditionHasResource('cacao', 20),
            conditionHasResource('sugar', 20),
        ]),
    },
};

export const processBuildingsUnlocks = (state: Draft<GameState>): void => {
    for (let building of buildingGroups) {
        const ownedBuilding = getBuilding(state, building);
        const rule = buildingsUnlocks[building];
        if (!isUnlocked(ownedBuilding) && rule.condition(state)) {
            if (rule.resource) {
                unlockResource(state, rule.resource);
            }
            setUnlocked(ownedBuilding);
            if (rule.owned) {
                ownedBuilding.amount = 1;
            }
        }
    }
};
