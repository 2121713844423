import { BuildingGroup } from "../buildings/buildingTypes";
import { GameState } from "../gamestate";
import { ResourceGroup } from "../resources/resourceTypes";
import { SpellType } from "../spells/spellTypes";

export type TemplateStringPartPlain = {
    kind: 'plain';
    value: string;
};
export type TemplateStringPartMultiplier = {
    kind: 'multiplier';
    value: number;
};
export type TemplateStringPartDuration = {
    kind: 'duration';
    value: number;
};
export type TemplateStringPartResourceAmount = {
    kind: 'resourceAmount';
    amount: number;
    resource: ResourceGroup;
};
export type TemplateStringPartResourceReference = {
    kind: 'resource';
    resource: ResourceGroup;
};
export type TemplateStringPartBuildingReference = {
    kind: 'building';
    building: BuildingGroup;
};
export type TemplateStringPartSpellReference = {
    kind: 'spell';
    spell: SpellType;
};
export type TemplateStringPartHighlight = {
    kind: 'highlight';
    value: string;
};

export type TemplateStringPart =
    | TemplateStringPartPlain
    | TemplateStringPartMultiplier
    | TemplateStringPartDuration
    | TemplateStringPartResourceAmount
    | TemplateStringPartResourceReference
    | TemplateStringPartBuildingReference
    | TemplateStringPartSpellReference
    | TemplateStringPartHighlight;

export type TemplateString = {
    parts: TemplateStringPart[];
};

export type TemplateStringMaker = (state: GameState) => TemplateString;

export function makeTemplateString(parts: TemplateStringPart[]): TemplateString {
    return { parts: parts };
}

export function plainString(s: string): TemplateStringPartPlain {
    return {
        kind: 'plain',
        value: s,
    };
}
export function multiplierString(n: number): TemplateStringPartMultiplier {
    return {
        kind: 'multiplier',
        value: n,
    };
}
export function durationString(n: number): TemplateStringPartDuration {
    return {
        kind: 'duration',
        value: n,
    };
}
export function resourceAmountString(res: ResourceGroup, amount: number): TemplateStringPartResourceAmount {
    return {
        kind: 'resourceAmount',
        resource: res,
        amount: amount,
    };
}
export function resourceReferenceString(res: ResourceGroup): TemplateStringPartResourceReference {
    return {
        kind: 'resource',
        resource: res,
    };
}
export function buildingReferenceString(building: BuildingGroup): TemplateStringPartBuildingReference {
    return {
        kind: 'building',
        building: building,
    };
}
export function spellReferenceString(spell: SpellType): TemplateStringPartSpellReference {
    return {
        kind: 'spell',
        spell: spell,
    };
}
export function highlightedString(s: string): TemplateStringPartHighlight {
    return {
        kind: 'highlight',
        value: s,
    };
}

export function simpleString(s: string): TemplateString {
    return makeTemplateString([plainString(s)]);
}

// Parse a string into the template string enum
// 'Plain text with $resource and @building, or #spells and also {important}'
export function templateStringFormat(s: string): TemplateString {
    var parts: TemplateStringPart[] = [];
    const regex = /(\$\w+)|(@\w+)|(#\w+)|(\{[^\}]+\})/g

    for (; ;) {
        const firstIndex = s.search(regex);

        if (firstIndex < 0) {
            if (s.length > 0) {
                parts.push(plainString(s));
            }
            return makeTemplateString(parts);
        }

        const entity = s.substring(firstIndex).match(regex)![0];
        var nextPart: TemplateStringPart
        if (entity[0] === '$') {
            nextPart = resourceReferenceString(entity.substring(1) as ResourceGroup);
        } else if (entity[0] === '@') {
            nextPart = buildingReferenceString(entity.substring(1) as BuildingGroup);
        } else if (entity[0] === '#') {
            nextPart = spellReferenceString(entity.substring(1) as SpellType);
        } else {
            nextPart = highlightedString(entity.substring(1, entity.length - 1));
        }

        const firstPart = s.substring(0, firstIndex);
        if (firstPart.length > 0) {
            parts.push(plainString(firstPart));
        }

        parts.push(nextPart);
        s = s.substring(firstIndex + entity.length);
    }
}
